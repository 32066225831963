import React, {ReactNode} from 'react';

import {Layout, Select} from 'antd';
import {Rest, UserInfo} from './utils/utils';
import {MenuInfo} from 'rc-menu/lib/interface';
import {withTranslation, WithTranslation} from 'react-i18next';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import Flag from 'react-flagkit';
import AppMenu from './layout/AppMenu';
import AppHeader from './layout/AppHeaderContainer';
import AppContent from './layout/AppContent';
import MenuConfig, {COMPONENT_IDS} from './layout/menuConfig';
import ChangePasswordFormContainer from './changePassword/ChangePasswordFormContainer';
import PasswordAbouExpireModal from './changePassword/PasswordAbouExpireModal';
import EnumModuloType from './enum/EnumModuloType';
import EventHub, {Event} from './utils/eventHub';
import HomePageContainer from './homePage/HomePageContainer';
import OtpAuthenticationFormContainer from './otpAuthentication/OtpAuthenticationFormContainer';
import {UpOutlined} from '@ant-design/icons';
import i18n from './i18n/i18n';
import PrestadorConfigContext, {IPrestadorConfigData} from './context/PrestadorConfigContext';


export interface TabInfo {
    title: string;
    component: ReactNode;
    extendedProps?: TabExtendedProps;
}

export interface TabExtendedProps {
    id?: string;
    isModal?: boolean;
    ref?: any;
    closable?: boolean;
}

interface State {
    tabs: TabInfo[];
    activeTabIndex: number;
    menuCollapsed: boolean;
    changePasswordVisible?: boolean;
    changePasswordFormKey?: number;
    otpAuthenticationVisible?: boolean;
    otpAuthenticationFormKey?: number;
    passwordAbouExpireVisible?: boolean;
    passwordAbouExpireVisibleFormKey?: number;
    requiredChangePass?: boolean;
    language: string;
    prestadorConfigData: IPrestadorConfigData;
    loaded: boolean;
}

class App extends React.Component<WithTranslation & RouteComponentProps, State> {

    public state: State = {
        tabs: [],
        activeTabIndex: -1,
        menuCollapsed: true,
        language: i18n.language.substring(0, 2),
        loaded: false,
        prestadorConfigData: {},
    };

    private t = this.props.t;


    private setPrestadorConfigData = (ctxData: IPrestadorConfigData) => {
        this.setState({prestadorConfigData: ctxData});
    };


    public componentDidMount() {
        UserInfo.setCookie();
        this.addTab(this.props.t('homePage:tabTitle'), <HomePageContainer closeTab={this.closeActiveTab}
            addTab={this.addTab}/>, MenuConfig.getConfigById(COMPONENT_IDS.homePageContainer), () => this.loadData());
    }

    public render() {
        return (
            this.state.loaded ?
                <PrestadorConfigContext.Provider
                    value={{data: this.state.prestadorConfigData, fn: this.setPrestadorConfigData}}>
                    <Layout style={{minHeight: '100vh'}}>
                        <Layout.Header className='header-app'>
                            <AppHeader
                                logout={this.logout}
                                changePassword={this.changePassword}
                                otpAuthentication={this.otpAuthentication}
                                addTab={this.addTab}
                            />
                        </Layout.Header>
                        <Layout className='content-app'>
                            <Layout.Sider
                                collapsible
                                collapsed={this.state.menuCollapsed}
                                onCollapse={this.toogleMenuCollapse}
                                trigger={null}>
                                <div className='content-app-menu'>
                                    <AppMenu onMenuOptionSelect={this.onMenuOptionSelect}/>
                                    <Select
                                        className='language-selector'
                                        defaultValue={this.state.language}
                                        suffixIcon={<UpOutlined/>}
                                        onChange={this.onChangeLanguage}
                                        options={[
                                            {value: 'es', label: <Flag country='ES'/>},
                                            {value: 'en', label: <Flag country='GB'/>},
                                        ]}/>
                                </div>
                            </Layout.Sider>
                            <Layout.Content className='main-layout'>
                                <AppContent tabs={this.state.tabs}
                                            activeTabIndex={this.state.activeTabIndex}
                                            onTabsChange={this.onTabsChange}
                                            onActiveTabChange={this.onActiveTabChange}
                                            onCloseTab={this.closeTab}/>
                            </Layout.Content>
                        </Layout>
                    </Layout>

                    {this.changePasswordFormModal()}
                    {this.passwordAbouExpireFormModal()}
                    {this.otpAuthenticationFormModal()}
                </PrestadorConfigContext.Provider>
                : <></>
        );
    }

    private loadData = () => {
        const promises: Array<Promise<any>> = [];
        const req1 = Rest<{ type: string }, any>();
        const req2 = Rest<{ type: string }, IPrestadorConfigData>();
        promises.push(req1.operation({type: 'GetchangePasswordLastUpdate'}));
        promises.push(req2.operation({type: 'GetPrestadorConfig'})); // PrestadorConfig

        let currentState = this.state;


        Promise.all(promises).then(response => {
            if (response[0].passwordChangeAdvised) {
                currentState = {
                    ...currentState,
                    passwordAbouExpireVisible: true,
                    passwordAbouExpireVisibleFormKey: new Date().getTime(),
                };
            } else if (response[0].passwordChangeRequired) {
                currentState = {
                    ...currentState,
                    changePasswordVisible: true,
                    requiredChangePass: true,
                    changePasswordFormKey: new Date().getTime(),
                };
            }


            this.setState({
                ...currentState,
                loaded: true,
                prestadorConfigData: {defaultDocumentTypePassport: response[1].defaultDocumentTypePassport, allowPatientDataPasting: response[1].allowPatientDataPasting,vidalEnabled: response[1].vidalEnabled, vidalAppToken: response[1].vidalAppToken},
            });

        });
    };

    private onChangeLanguage = (language: string) => {
        i18n.changeLanguage(language);
        UserInfo.setCookie();
        this.setState({language: language, tabs: this.state.tabs});
    };

    private changePasswordFormModal = () => {
        return (
            <ChangePasswordFormContainer
                key={this.state.changePasswordFormKey} // Force form regeneration
                requiredChangePass={this.state.requiredChangePass}
                visible={this.state.changePasswordVisible}
                onClose={this.onCloseFormChangePassword}
            />
        );
    };

    private passwordAbouExpireFormModal = () => {
        return (
            <PasswordAbouExpireModal
                key={this.state.passwordAbouExpireVisibleFormKey} // Force form regeneration
                visible={this.state.passwordAbouExpireVisible}
                onClose={this.onCloseFormPasswordAbouExpire}
                onChange={this.onChange}
            />
        );
    };

    private otpAuthenticationFormModal = () => {
        return (
            <OtpAuthenticationFormContainer
                key={this.state.otpAuthenticationFormKey}
                visible={this.state.otpAuthenticationVisible}
                onClose={this.onCloseFormOtpAuthentication}/>
        );
    };

    private onCloseFormPasswordAbouExpire = () => {
        this.setState({passwordAbouExpireVisible: false});
    };

    private onCloseFormOtpAuthentication = () => {
        this.setState({otpAuthenticationVisible: false});
    };

    private onChange = () => {
        this.onCloseFormPasswordAbouExpire();
        this.changePassword();
    };

    private onMenuOptionSelect = (info: MenuInfo, extendedProps: TabExtendedProps) => {

        let tabCaption = MenuConfig.getConfigById(info.key.toString()).tabCaption;
        tabCaption = this.t(tabCaption);

        const component = MenuConfig.getComponentById(info.key.toString(), this.addTab, this.closeActiveTab);
        this.addTab(tabCaption, component, extendedProps);
    };

    private closeTab = (tabToCloseIndex: number) => {
        const newActive =
            this.state.activeTabIndex >= this.state.tabs.length - 1
                ? this.state.activeTabIndex - 1
                : this.state.activeTabIndex;
        const newTabs = this.state.tabs.filter(
            (tab, index) => index !== tabToCloseIndex,
        );

        if (this.state.tabs[tabToCloseIndex].extendedProps?.id === COMPONENT_IDS.newPrescriptionContainer) {
            EventHub.trigger(Event.RELOAD_PRESCRIPTIONS);
        }

        this.setState({activeTabIndex: newActive, tabs: newTabs});
    };

    private closeActiveTab = () => {
        this.closeTab(this.state.activeTabIndex);
    };

    private onTabsChange = (tabs: TabInfo[]) => {
        this.setState({tabs});
    };

    private onActiveTabChange = (activeTabIndex: number, callback?: () => void) => {
        this.setState({activeTabIndex});

        callback && callback();
    };

    private addTab = (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps, callback?: () => void) => {

        const existingTabIndex = this.state.tabs.findIndex(t => t.title === tabCaption);

        if (existingTabIndex !== -1) {
            if (tabCaption === this.props.t('newPrescription')) {

                const nNewPrescriptions = this.state.tabs.filter(t => t.title.includes(tabCaption)).length;
                let tabs = this.state.tabs.slice(0);
                tabs.push({title: tabCaption + ' ' + (nNewPrescriptions + 1), component: component, extendedProps});
                const activeTabIndex = tabs.length - 1;
                this.setState({tabs: tabs, activeTabIndex}, callback);
            } else {
                this.onActiveTabChange(existingTabIndex, callback);
            }

        } else {
            let tabs = this.state.tabs.slice(0);
            tabs.push({title: tabCaption, component: component, extendedProps});
            const activeTabIndex = tabs.length - 1;
            this.setState({tabs: tabs, activeTabIndex}, callback);
        }

    };

    private logout = () => {
        Rest<{ type: string, typeModulo: number }, {}>().operation({
            type: 'Logout',
            typeModulo: EnumModuloType.MODULO_PRESCRIPTOR,
        })
            .then(() => {
                this.props.history.push('/');
            });
    };

    private toogleMenuCollapse = () => {
        this.setState({menuCollapsed: !this.state.menuCollapsed});
    };

    private changePassword = () => {
        this.setState({changePasswordVisible: true, changePasswordFormKey: new Date().getTime()});
    };

    private otpAuthentication = () => {
        this.setState({otpAuthenticationVisible: true, otpAuthenticationFormKey: new Date().getTime()});
    };

    private onCloseFormChangePassword = () => {
        if (this.state.requiredChangePass) {
            this.logout();
        } else {
            this.setState({changePasswordVisible: false, requiredChangePass: false});
        }
    };
}

export default withTranslation(['menu', 'config'])(withRouter(App));
