import React, { createRef } from 'react';

import { Space, Button, Row, Col, Tooltip, Dropdown, Menu, Popconfirm, Collapse, Empty, Badge } from 'antd';
import { withTranslation, WithTranslation } from 'react-i18next';
import {Functions} from '../utils/utils';
import { IState as IStateContainer } from './newPrescriptionsContainer';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons/faSave';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons/faCheckCircle';
import { faPenNib } from '@fortawesome/free-solid-svg-icons/faPenNib';
import { faEye } from '@fortawesome/free-solid-svg-icons/faEye';
import { faPrescription } from '@fortawesome/free-solid-svg-icons/faPrescription';
import EnumPrescriptionType from '../enum/EnumPrescriptionType';
import PrescripcionPosologyInfo from './entities/prescripcionPosologyInfo';
import { faCopy, faEdit, faExclamation, faExclamationCircle, faExclamationTriangle, faKey, faPencilAlt, faStar } from '@fortawesome/free-solid-svg-icons';
import { faTimesCircle, faTrashAlt } from '@fortawesome/free-regular-svg-icons';
import {IconDefinition} from "@fortawesome/fontawesome-svg-core";
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EnumPrescriptionStatus from '../enum/EnumPrescriptionStatus';
import PrescriptionReceipts from './prescriptionReceipts';
import EnumEmittedPrescriptionAction from '../enum/EnumEmittedPrescriptionAction';
import SubMenu from 'antd/lib/menu/SubMenu';
import EnumCodeIdentificationType from '../enum/EnumCodeIdentificationType';
import PrescriptionOptionsContainer from './options/PrescriptionOptionsContainer';
import i18n from '../i18n/i18n';
import CollapsePanel from 'antd/lib/collapse/CollapsePanel';
import { DownOutlined } from '@ant-design/icons';
import PrescriptionAlertsPanelContainer from './alerts/PrescriptionAlertsPanelContainer';
import PrestadorConfigContext from '../context/PrestadorConfigContext';
import MedicineInfoPopOver from './savePrescription/medicine/MedicineInfoPopOver';
import Medicine from './savePrescription/entities/medicine';
import PrescripcionesAnterioresContainer from './anteriores/PrescripcionesAnterioresContainer';
import PrescripcionAnterior from './anteriores/entities/PrescripcionAnteriorData';
import MUFACE_ICONO from '../images/mufaceIcono.png';
import InformeVisadoMuface from './visado/informeVisadoMuface';

interface IProps {
    pending?: boolean;
    fixedPatientLocation?: boolean;
    hideOptions?: boolean;
    onClickSelectPatient: () => void;
    onClickChangePatient: () => void;
    onClickPatientDetails: () => void;
    onClickPrescripcionesAnteriores: () => void;
    onClickSelectLocation: () => void;
    onClickChangeLocation: () => void;
    onClickLocationDetails: () => void;
    onClickSaveSign: () => void;
    onClickPrescriptionConfig: (typePrescription: number) => void;
    onClickPrescriptionFavList: () => void;
    onClickAditionalPatientInfo: () => void;
    deletePrescription: (idPrescription: number) => void;
    modifyPrescription: (idPrescription: number) => void;
    closeTab?: () => void;
    cancelPrescriptions: () => void;
    reactivatePrescription: (idPrescription: number) => void;
    voidPrescription: (idPrescription: number) => void;
    onClickEmittedActions: (action: number) => void;
    getPrescriptionTypeIcon: (type: number) => IconDefinition;
    consultReplace: (idPrescripcion: number, idTransaccion: number) => void;
    onClickSelectDiagnostico: () => void;
    deleteDiagnostico: () => void;
    onClickChangeDiagnostico: () => void;
    setMedicine: (medicine: any) => Medicine;
    setPrescriptionesAnteriores: (prescriptions: PrescripcionAnterior[]) => PrescripcionAnterior[];
    controlPsychotropicNarcotic: () => void;
    onCloseReceiptMenu: () => void;
    onClickButtonAddFavorite: (pres: PrescripcionPosologyInfo) => void;
}

interface IState {
    containerHeight?: number;
    informeVisado?: {
        visible: boolean;
        key: number;
        idPrescripcion: number;
        nombreProducto: string;
    }
    criticAlerts: boolean;
}

class NewPrescription extends React.Component<IProps & WithTranslation & RouteComponentProps & IStateContainer, IState> {

    public embed: boolean = this.props.location.search.includes('embed');

    public state: IState = {criticAlerts: false};

    private containerRef = createRef<HTMLDivElement>();
    
    static contextType = PrestadorConfigContext;

    //static contextType = PrescriptionAlertsContext;

    public componentDidMount() {
        const marginBottom = 16;
        this.setState({ containerHeight: this.containerRef.current?.parentElement?.offsetHeight! - marginBottom });
    }

    public render() {

        const receiptMenu = (
            <Menu onClick={this.onClickReceiptMenu}>
                <Menu.Item key={EnumPrescriptionType.MEDICAMENTO}>{this.props.t('prescriptionType:' + EnumPrescriptionType.MEDICAMENTO)}</Menu.Item>
                <SubMenu title={this.props.t('prescriptionType:' + EnumPrescriptionType.PRODUCTO_SANITARIO)}>
                    <Menu.Item key={EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO}>{this.props.t('cataloged')}</Menu.Item>
                    <Menu.Item key={EnumPrescriptionType.PRODUCTO_SANITARIO}>{this.props.t('noCataloged')}</Menu.Item>
                </SubMenu>
                <SubMenu title={this.props.t('prescriptionType:' + EnumPrescriptionType.NUTRICION)}>
                    <Menu.Item key={EnumPrescriptionType.NUTRICION_CATALOGADA}>{this.props.t('cataloged')}</Menu.Item>
                    <Menu.Item key={EnumPrescriptionType.NUTRICION}>{this.props.t('noCataloged')}</Menu.Item>
                </SubMenu>
                <Menu.Item key={EnumPrescriptionType.VACUNA}>{this.props.t('prescriptionType:' + EnumPrescriptionType.VACUNA)} </Menu.Item>
                <SubMenu title={this.props.t('prescriptionType:' + EnumPrescriptionType.FORMULA_MAGISTRAL)}>
                    <Menu.Item key={EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA}>{this.props.t('cataloged')}</Menu.Item>
                    <Menu.Item key={EnumPrescriptionType.FORMULA_MAGISTRAL}>{this.props.t('noCataloged')}</Menu.Item>
                </SubMenu>
                <Menu.Item key={EnumPrescriptionType.FAVORITA} icon={<FontAwesomeIcon icon={faStar}/>} title={this.props.t('prescriptionFavorite')}>
                        {" "+this.props.t('prescriptionFavorite')}
                </Menu.Item>
            </Menu>
        )

        const visadoTooltip = (pres: PrescripcionPosologyInfo) => {
            return (
                <div>
                    <span>{this.props.t('medicineConfig:hastaVisado')} <b>{Functions.dateToString(pres.dateFinVisado)}</b>. {this.props.t('medicineConfig:visadoInforme')}</span>
                </div>
            )
        }

        const vidalEnabled = this.context.data.vidalEnabled;

        const emitirButton = (disabled: boolean) => { return <Button type='primary' icon={<FontAwesomeIcon className='anticon' icon={faPenNib} />} onClick={() => this.props.onClickSaveSign()} disabled={!this.props.prescriptions || this.props.prescriptions.length === 0 || disabled}>
            {this.props.t('saveSignButton')}
        </Button>}

        const prescripcionesFirstIndex = this.embed ? 1 : 4;

        return (
            <>
            <div ref={this.containerRef} className={'new_prescription_container' + (this.embed ? ' embed' : '')} 
                style={{height: this.state.containerHeight + 'px'}}>
                <div className='prescription_buttons_container'>
                    <Space size='small' className='table-button-bar'>
                        {this.props.pending ?
                        <>

                            {((!this.props.diagnosticoSelected && this.props.prescriptions && this.props.prescriptions.some((p) => p.flagVisado == 1) ) || (this.state.criticAlerts && this.props.prescriptions && this.props.prescriptions.length!=0)) ?
                                <div className='boton__emitir--container'>
                                    {emitirButton(!this.props.diagnosticoSelected && this.props.prescriptions!=undefined && this.props.prescriptions.some((p) => p.flagVisado == 1)==true)}
                                    <Tooltip overlayClassName='boton__emitir--tooltip' placement='bottomLeft' arrowPointAtCenter={true}
                                            title={<><span className='title'>{this.props.t('emitirAlertasTitle')}</span>
                                            <ul>
                                                {!this.props.diagnosticoSelected && this.props.prescriptions && this.props.prescriptions.some((p) => p.flagVisado == 1) && <li>{this.props.t('emitirAlertasVisadoDiagnosticoTexto')}</li>}
                                                {this.state.criticAlerts && <li>{this.props.t('emitirAlertasCriticoTexto')}</li>}
                                            </ul></>}>
                                        <div className='boton__emitir--exlamacion'><FontAwesomeIcon  icon={faExclamation} /></div>
                                    </Tooltip>
                                </div>
                                : emitirButton(false)}
                            <Button icon={<FontAwesomeIcon icon={faSave} className='anticon' />}  disabled={!this.props.prescriptions || this.props.prescriptions.length === 0} onClick={this.props.closeTab}>
                                {this.props.t('emitOtherMomentButton')}
                            </Button>
                            <Popconfirm title={this.props.t('cancelButtonConfirmation')} onConfirm={() => this.props.cancelPrescriptions()} okText={this.props.t('buttons:confirmar')} cancelText={this.props.t('buttons:cancel')}>
                                <Button type='primary' danger icon={<FontAwesomeIcon className='anticon' icon={faTrashAlt} />}>
                                    {this.props.t('cancelButton')}
                                </Button>
                            </Popconfirm>
                        </>
                        :
                        <>
                            <Button type='primary' icon={<FontAwesomeIcon className='anticon' icon={faCopy} />} onClick={() => this.props.onClickEmittedActions(EnumEmittedPrescriptionAction.NEW)} >
                                {this.props.t('newOnePrescriptionButton')}
                            </Button>
                            <Button icon={<FontAwesomeIcon icon={faPencilAlt} className='anticon' />} onClick={() => this.props.onClickEmittedActions(EnumEmittedPrescriptionAction.MODIFY)}>
                                {this.props.t('modifyPrescriptionButton')}
                            </Button>
                            {this.props.prescriptions?.find(p => p.typeEstado !== EnumPrescriptionStatus.ANULADA) &&
                            <Button type='primary' danger icon={<FontAwesomeIcon className='anticon' icon={faTrashAlt} />} onClick={() => this.props.onClickEmittedActions(EnumEmittedPrescriptionAction.VOID)}>
                                {this.props.t('anularButton')}
                            </Button>}
                        </>
                        }
                    </Space>
                    {!this.props.pending && !this.props.hideOptions && <PrescriptionOptionsContainer idPatient={this.props.patientSelected!.idPaciente!} idPrescripcionGrupo={this.props.idPrescriptionGroup!} />}
                </div>
                <div style={{maxHeight: 'calc(100% - 32px)', overflowY: 'auto', overflowX: 'hidden'}}>
                    <Row gutter={[24, 24]}>
                        <Col span={24}>
                            <Collapse bordered={false} collapsible={!this.props.selectedLocation ? 'disabled' : undefined}  expandIconPosition={'right'} expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? -180 : 0} style={{color:"lightgray",fontSize:'1.5rem',top:'0px',visibility:this.props.selectedLocation ? "visible":"hidden"}}/>}>
                                <CollapsePanel
                                    header={(!this.props.selectedLocation ?
                                        
                                                <Row align='middle'  className='data_container'>
                                                    <div className='number'>1</div>
                                                    <span className='text_title'>
                                                        {this.props.t('locationTitle')}
                                                    </span>
                                                    <Button type="link" className='link_title' onClick={this.props.onClickSelectLocation}>{this.props.t('selectLocationButton')}</Button>
                                                </Row>
                                                :
                                                <div className='data_container' onClick={e => e.stopPropagation()}>
                                                    <Row align='top' wrap={false}>
                                                        <div className='title'>
                                                            <FontAwesomeIcon icon={faCheckCircle} className="number"/>
                                                            <span className='text_title'>
                                                                {this.props.t('locationTitle')}
                                                            </span>
                                                        </div>
                                                        <div className='paciente-title-container'>
                                                            <span className='title-value-container'>
                                                                <b>{this.props.selectedLocation.nameLocalizacion+ (this.props.selectedLocation.valueDireccion ? " - " : "")}</b>
                                                            </span>
                                                            {this.props.selectedLocation.valueDireccion && <span className='address-title'>{this.props.selectedLocation.valueDireccion}</span>}
                                                            {this.props.pending && !this.props.fixedPatientLocation &&
                                                            <>
                                                                <Button type="link" className='link_title'
                                                                    onClick={this.props.onClickChangeLocation}>{this.props.t('changeLocationButton')}</Button>
                                                                <Tooltip title={this.props.selectedLocation.idPrestador === undefined? undefined :this.props.t('editLocation')}>
                                                                    <Button className='button__details'
                                                                        disabled={this.props.selectedLocation.idPrestador === undefined}
                                                                        icon={<FontAwesomeIcon icon={faEye} className='anticon' />}
                                                                        onClick={this.props.onClickLocationDetails} />
                                                                </Tooltip>
                                                            </>}
                                                        </div>
                                                    </Row>
                                                </div>
                                                    
                                        )}
                                        children={(this.props.selectedLocation ?
                                            <Col span={24} className='collapseChildren'>
                                            <div className='data_container collapseChildren'>
                                            <Row className='location_data_container'>
                                                <div>
                                                    
                                                    {this.props.selectedLocation!.valueDireccion && <span>{this.props.selectedLocation!.valueDireccion}</span>}
                                                    {this.props.selectedLocation!.valueDireccion2 && " - "+this.props.selectedLocation!.valueDireccion2}
                                                    {this.props.selectedLocation!.codeCodigoPostal && 
                                                    <span>
                                                        {" - "+this.props.selectedLocation!.codeCodigoPostal + ', ' +
                                                        this.props.selectedLocation!.nameMunicipio + ', ' + this.props.selectedLocation!.nameProvincia}
                                                    </span>}
                                                </div>
                                            </Row>
                                            
                                            <Row>
                                               <Col>
                                                <span><b>{this.props.t('emailLabel')+" "}</b></span>
                                                {this.props.selectedLocation!.valueEmail ? <span>{this.props.selectedLocation!.valueEmail}</span> :
                                                    <span className='nodata'>{this.props.t('noDataLabel')}</span>}
                                                </Col>
                                                <Col>
                                                <span className='space_title'><b>{this.props.t('phoneLabel')+" "}</b>
                                                {this.props.selectedLocation!.valueTelefono ? this.props.selectedLocation!.valueTelefono :
                                                    <span className='nodata'>{this.props.t('noDataLabel')}</span>}
                                                </span>
                                                </Col>
                                            </Row>
                                            </div>
                                            </Col>
                                        :Empty)}
                                        key={'1'}
                                    />
                                </Collapse>
                        </Col>

                        <Col span={24}>
                            <Collapse bordered={false} collapsible={!this.props.patientSelected ? 'disabled' : undefined} expandIconPosition={'right'} expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? -180 : 0} style={{color:"lightgray",fontSize:'1.5rem',top: "2.055px",visibility:this.props.patientSelected ? "visible":"hidden"}}/>}>
                                <CollapsePanel
                                    key={"2"}
                                    header={!this.props.patientSelected ?             
                                        <Row align='middle' className='data_container'>
                                            <div className='number'>2</div>
                                            <span className='text_title'>
                                                {this.props.t('pacienteTitle')}
                                            </span>
                                            <Button type="link" className='link_title' onClick={this.props.onClickSelectPatient}>{this.props.t('selectPatientButton')}</Button>
                                        </Row>
                                        :
                                        <div className='data_container paciente' onClick={e => e.stopPropagation()}>
                                            <Row align='top' wrap={false}>
                                                <div className='title'>
                                                    <FontAwesomeIcon icon={faCheckCircle} className='number'/>
                                                    <span className='text_title'>
                                                        {this.props.t('pacienteTitle')}
                                                    </span>
                                                </div>
                                                <div className='paciente-title-container'>
                                                    <div style={{display: 'flex', alignItems:'center', flexWrap: 'nowrap'}}>
                                                        {this.props.flagPrestadorHabilitado && <span className='paciente-mutualista-container'>
                                                            <Tooltip title={this.props.flagPacienteMutualista ? 
                                                                    this.props.t('pacienteMutualidadSi') : this.props.t('pacienteMutualidadNo')}>
                                                                <div className='selectMedicineNameDiv'>
                                                                    <div className={'circleFlag '+ (this.props.flagPacienteMutualista ? 'normal': 'bn')}><img src={MUFACE_ICONO}/></div>
                                                                </div>
                                                            </Tooltip>
                                                        </span>}
                                                        <span className='title-value-container'>
                                                            <b>{this.props.patientSelected.namePersona+" - "}</b>    
                                                        </span>
                                                    </div>
                                                    <span className='title-nif-container'>{(this.props.patientSelected.typeIdentificacion === EnumCodeIdentificationType.MENOR ?this.props.t('others:menorSinNIF') : this.props.patientSelected.codeIdentificacion)}</span>

                                                    {!this.props.fixedPatientLocation &&
                                                    <>
                                                        {this.props.pending && <Button type="link" className='link_title' onClick={this.props.onClickChangePatient}>{this.props.t('changePatientButton')}</Button>}
                                                        {this.props.pending &&<Tooltip title={this.props.t('seeTooltip')}>
                                                            <Button className='button__details' icon={<FontAwesomeIcon icon={faEye} className='anticon' />}
                                                                onClick={() => this.props.onClickPatientDetails()}/>
                                                        </Tooltip>}
                                                        <Tooltip title={this.props.t('verPrescripcionesAnteriores')}>
                                                            <Button className='button__prescription' type="primary"
                                                                    icon={<FontAwesomeIcon icon={faPrescription} style={{ color: '#ffffff', cursor: 'pointer', fontSize: '0.8rem' }} />}
                                                                    onClick={this.props.onClickPrescripcionesAnteriores}/>
                                                        </Tooltip>
                                                    </>
                                                    }
                                                    <div className='aditionalPatientandNumberContainer'>
                                                        <Button size="small" type="primary" className={"button_aditional_info "+this.props.pending}
                                                                icon={<FontAwesomeIcon icon={faEdit} style={{marginRight: '8px'}}/>}  
                                                                onClick={this.props.onClickAditionalPatientInfo}>
                                                            {this.props.t('aditionalPatientInformation')}
                                                        </Button>
                                                        {(this.props.patientSelected.alergias || this.props.patientSelected.patologias) &&
                                                            <div className={"numberAlergiesPatologies "+this.props.pending}>
                                                                {this.props.patientSelected.alergias? (this.props.patientSelected.alergias.length)+(this.props.patientSelected.patologias? this.props.patientSelected.patologias.length : 0): (0 + (this.props.patientSelected.patologias? this.props.patientSelected.patologias.length:0))}
                                                            </div>
                                                        }
                                                    </div>
                                                </div>
                                            </Row>
                                        </div>
                                    }
                                    children={this.props.patientSelected ? 
                                        <Col span={24} className='collapseChildren'>
                                            <div className='data_container collapseChildren patient'>
                                                <div className='pacienteDataContainer'>
                                                <div>
                                                        <Row>
                                                            <Col>
                                                                <div>
                                                                    <b>{this.props.t('namePatientTitle')}</b> {this.props.patientSelected!.namePersona}  
                                                                </div>   
                                                                <div>
                                                                    <b>{this.props.t('dateBirthLabel')}</b> {Functions.dateToString(this.props.patientSelected!.dateNacimiento)}
                                                                </div>
                                                                <div>
                                                                    {this.props.patientSelected!.valueAltura ? <span><b>{this.props.t('alturaLabel')} </b></span>:<></>}
                                                                        {this.props.patientSelected!.valueAltura ?  this.props.patientSelected!.valueAltura+"cm" : <></>}
                                                                </div>
                                                                <div>
                                                                    <span><b> {this.props.t('genderLabel')}</b></span> {this.props.t('gender:' + this.props.patientSelected!.typeSexo)}
                                                                   
                                                                </div>                                           
                                                                <div>
                                                                    <b>{this.props.t('mutualidadLabel')}</b> {this.props.patientSelected!.nameMutualidad ? this.props.patientSelected!.nameMutualidad : <span className='nodata'>{this.props.t('noDataLabel')}</span>}
                                                                    
                                                                </div>
                                                            </Col>  
                                                           
                                                        </Row>
                                                       
                                                </div>
                                                <div>
                                                    <Row>
                                                        <Col>
                                                            <div className=''>
                                                            <b>{this.props.t('codIdentificationLabel')}</b> {this.props.patientSelected!.typeIdentificacion === EnumCodeIdentificationType.MENOR ?
                                                                this.props.t('others:menorSinNIF') : this.props.patientSelected!.codeIdentificacion}
                                                            </div>
                                                            <div className=''><b> {this.props.t('ageLabel')+" "}</b>{Functions.calculateAge(this.props.patientSelected!.dateNacimiento)}</div> 
                                                            <div className=''>
                                                            {this.props.patientSelected!.valuePeso ? <span className={this.props.patientSelected!.valueAltura ?'':''}><b>{this.props.t('pesoLabel')} </b></span>:<></>}
                                                                {this.props.patientSelected!.valuePeso ?  this.props.patientSelected!.valuePeso+"kg": <></>}
                                                            </div>
                                                            <div className=''>
                                                            {this.props.patientSelected!.flagLactancia ? <span><b>{this.props.t('lactanciaLabel')} </b></span>:<></>}
                                                                        {this.props.patientSelected!.flagLactancia ?  (this.props.patientSelected!.flagLactancia ? this.props.t('yes'): this.props.t('no')) : <></>}
                                                            </div>
                                                            <div className=''>
                                                                {this.props.patientSelected!.flagEmbarazo ?<span><b>{this.props.t('embarazoLabel')} </b></span>:<></>}
                                                                {this.props.patientSelected!.flagEmbarazo ?  this.props.t('semana')+this.props.patientSelected!.flagEmbarazo : <></>}
                                                            </div>
                                                            <div>
                                                                <span className=''><b>{this.props.t('aseguradoraLabel')} </b></span>
                                                                    {this.props.patientSelected!.nameAseguradora ? this.props.patientSelected!.nameAseguradora : <span className='nodata'>{this.props.t('noDataLabel')}</span>}
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                </div>
                                                <div>
                                                    <Row>
                                                        <Col>
                                                        {this.props.patientSelected!.alergias?.length!=0? <div className='colAlergiasList'><span><b>{this.props.t('alergiasLabel')}</b></span>
                                                            {this.props.patientSelected!.alergias?.map((a, i) =>{
                                                                const nombre = i18n.language.substring(0, 2) == 'es' ? (a.nameAlergiaEs ? a.nameAlergiaEs : a.nameAlergiaEn) : (a.nameAlergiaEn ? a.nameAlergiaEn : a.nameAlergiaEs);
                                                                return <div key={i} className='patientListElement'>{a.idVidal!+" - "+nombre!}</div>
                                                                }
                                                            )}
                                                            </div>:<></>}
                                                        </Col>
                                                        <Col>
                                                        {this.props.patientSelected!.patologias?.length!=0? <div><span><b>{this.props.t('patologiasLabel')} </b></span>
                                                            {this.props.patientSelected!.patologias?.map((a, i) =>{
                                                                const nombre = i18n.language.substring(0, 2) == 'es' ? (a.nameCie10Es ? a.nameCie10Es : a.nameCie10En) : (a.nameCie10En ? a.nameCie10En : a.nameCie10Es);
                                                                return <div key={i} className='patientListElement'>{a.codeCie10!+" - "+nombre!}</div>
                                                                }
                                                            )}</div>: <></>}
                                                        </Col>
                                                    </Row>
                                                </div>
                                                </div>
                                                
                                            </div>
                                        </Col>
                                        :
                                        Empty
                                    }

                                />
                            </Collapse>
                            <Collapse className={'activeprescription-collapse'} bordered={false} collapsible={this.props.prescripcionesActivas?.length==0 ? 'disabled': undefined} expandIconPosition={'right'} expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? -180 : 0} style={{color:"#0091FF", fontSize:'1rem', visibility:this.props.prescripcionesActivasVisible && this.props.prescripcionesActivas?.length!=0 && this.props.pending ? "visible":"hidden"}}/>}>
                                <CollapsePanel
                                    key={"2"}
                                    header={!this.props.prescripcionesActivasVisible || this.props.prescripcionesActivas?.length==0 || !this.props.pending ?             
                                        Empty
                                        :
                                        <div className='activeprescription-header' onClick={e => e.stopPropagation()}>
                                            {this.props.t('prescriptionsActiv',{n:this.props.prescripcionesActivas?.length})}
                                        </div>
                                    }
                                
                                    children={this.props.prescripcionesActivasVisible ? 
                                        <Col span={24} className='collapseChildren'>
                                            <PrescripcionesAnterioresContainer visible={this.props.prescripcionesActivasVisible} patientSelected={this.props.patientSelected}
                                            getPrescriptionTypeIcon={this.props.getPrescriptionTypeIcon}
                                            prescripcionesActivas={this.props.prescripcionesActivas}
                                            setPrescriptionesAnteriores={this.props.setPrescriptionesAnteriores}
                                            />
                                        </Col>
                                        :
                                        Empty
                                    }

                                />
                            </Collapse>
                        </Col>

                        {!this.embed && (!this.props.diagnosticoSelected ?
                            this.props.pending && <Col span={24}>
                                <Row align='middle' className='data_container'>
                                    <div className='number'>3</div>
                                    <span className='text_title'>
                                        {this.props.t('diagnosticoTitle')}
                                    </span>
                                    <Button type="link" className='link_title' onClick={this.props.onClickSelectDiagnostico}>{this.props.t('selectDiagnosticoButton')}</Button>
                                    <span className='optionalText'>
                                        {this.props.t('optional')}
                                    </span>
                                </Row>
                            </Col>
                            :
                            <Col span={24}>
                                <div className='data_container'>
                                    <Row align='middle' wrap={false}>
                                        <div className='title'>
                                            <FontAwesomeIcon icon={faCheckCircle} className='number'/>
                                            <span className='text_title'>
                                                {this.props.t('diagnosticoTitle')}
                                            </span>
                                        </div>
                                        <div className='paciente-title-container'>
                                            <div className='title-value-container'>
                                                <b>{i18n.language.substring(0, 2)=='es' ? (this.props.diagnosticoSelectedNombre==""? this.props.diagnosticoSelectedName:this.props.diagnosticoSelectedNombre): (this.props.diagnosticoSelectedName==""? this.props.diagnosticoSelectedNombre:this.props.diagnosticoSelectedName)}</b>
                                            </div>
                                            {this.props.pending && <Button type="link" className='link_title' onClick={this.props.onClickChangeDiagnostico}>{this.props.t('changeDiagnosticoButton')}</Button>}
                                            {this.props.pending &&
                                            <>
                                                <Popconfirm title={this.props.t('deletionConfirmation')} onConfirm={this.props.deleteDiagnostico} okText={this.props.t('buttons:confirmar')} cancelText={this.props.t('buttons:cancel')}>
                                                    <Button className='button__prescription' type="primary" danger icon={<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                                                </Popconfirm>
                                            </>}
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        )}

                        {this.props.prescriptions?.map((pres, i) => {
                            return (
                                <Col span={24} key={i}>
                                    <div className='data_container'>
                                        <Row align='middle' justify='space-between'>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                <FontAwesomeIcon icon={faCheckCircle} className='number' />
                                                <span className='text_title'>
                                                    {this.props.t('prescriptionPosologyTitle')}
                                                </span>
                                                {this.props.pending && <Button type="link" className='link_title' onClick={() => this.props.modifyPrescription(pres.idPrescripcion)}>{this.props.t('modifyReceiptButton')}</Button>}
                                                {pres.codeConfidencial &&
                                                    <Tooltip title={pres.codeConfidencial}>
                                                        <Button className='button__details' type="primary" icon={<FontAwesomeIcon icon={faKey} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                                                    </Tooltip>
                                                }
                                                {this.props.pending &&
                                                    <Popconfirm title={this.props.t('deletionConfirmation')} onConfirm={() => this.props.deletePrescription(pres.idPrescripcion)} okText={this.props.t('buttons:confirmar')} cancelText={this.props.t('buttons:cancel')}>
                                                        <Button  className={!pres.codeConfidencial && !!pres.flagVisado ? 'button__details' : 'button__prescription'} type="primary" danger icon={<FontAwesomeIcon icon={faTrashAlt} style={{ color: '#ffffff', fontSize: '0.8rem' }} />} />
                                                    </Popconfirm>
                                                
                                                }
                                                <Tooltip title={this.props.t('addToFavorite')}>
                                                    <Popconfirm title={this.props.t('addToFavoriteConfirmation')} onConfirm={() => this.props.onClickButtonAddFavorite(pres)} okText={this.props.t('buttons:confirmar')} cancelText={this.props.t('buttons:cancel')}>

                                                            <Button className='button__prescription' type="primary"
                                                                    icon={<FontAwesomeIcon icon={faStar} 
                                                                    style={{ color: '#ffffff', cursor: 'pointer', fontSize: '0.8rem' }} />}
                                                            />
                                                    </Popconfirm>
                                                </Tooltip>
                                            </Col>
                                            <Col style={{display: 'flex', alignItems: 'center'}}>
                                                {!!pres.flagVisado &&
                                                    <Tooltip placement='topRight' arrowPointAtCenter={true} title={() => visadoTooltip(pres)} >
                                                        <div className='selectMedicineNameDiv'>
                                                            <div onClick={() => this.mostrarInformeVisado(pres.idPrescripcion, pres.valuePresentacion)} className='circleFlag visado'>V</div>
                                                        </div>
                                                        
                                                    </Tooltip>
                                                }
                                                {pres.flagMutualista &&
                                                    <Tooltip placement='topRight' arrowPointAtCenter={true} title={this.props.t('prescripcionMuface')}>
                                                        <div className='selectMedicineNameDiv'>
                                                            <div className='circleFlag'><img src={MUFACE_ICONO}/></div>
                                                        </div>
                                                    </Tooltip>
                                                }
                                            </Col>
                                        </Row>
                                        <div className='medication-card__container'>
                                            <div className='medication-card-info'>
                                                {pres.photos ?
                                                <div className='medication-photo'>
                                                    <div className='loading-img'>
                                                        <img alt='' src={pres?.photos[0].valueImagen} onLoad={e => {
                                                            e.currentTarget.parentElement!.classList.remove('loading-img') }} />
                                                    </div>
                                                </div> :
                                                <div className='medication-photo-placeholder'>
                                                    <FontAwesomeIcon icon={this.props.getPrescriptionTypeIcon(pres.typeProducto)}/>
                                                </div>
                                                }
                                                <div className='medication-info'>
                                                    <div className='medication-type--container'>
                                                        <span className='medication-type'>
                                                            <FontAwesomeIcon icon={this.props.getPrescriptionTypeIcon(pres.typeProducto)} style={{marginRight: '6px'}}/> {this.props.t('prescriptionType:' + pres.typeProducto).toUpperCase()}
                                                        </span>
                                                        {(pres.codeDcpf || pres.codeCodigoNacional || pres.codeCodigoNacionalPres) && <span className='medication-type'>
                                                            {pres.codeDcpf ? this.props.t('genericoDcpf'): this.props.t('nationalCode')}
                                                            <span className='code-medication'>{pres.codeDcpf ? pres.codeDcpf : pres.codeCodigoNacional ? pres.codeCodigoNacional : pres.codeCodigoNacionalPres}</span>
                                                        </span>}
                                                        <span className='medication-type'>
                                                            {this.props.t('prescription')}
                                                            <span className='code-medication'>{pres.idPrescripcion}</span>
                                                        </span>
                                                        {this.getPrescriptionStatus(pres.typeEstado, pres.dateEmision, pres.dateAnulacion)}
                                                        {pres.flagVisado && pres.typeEstado!=EnumPrescriptionStatus.PTE_FIRMA ? this.getVisadoStatus(pres.typeStatusVisado,pres.typeDenegacionVisado,pres.descDenegacionVisado):<></>}
                                                    </div>
                                                    <div className='medication-name'>
                                                            {pres.valueComposicionFmv ?
                                                                <b>{pres.valueComposicionFmv}</b>
                                                            :
                                                                <><b>{pres.valuePresentacion}</b></>
                                                            }
                                                        <span className='medicine-info-newprescription-icon'>
                                                            {pres.idVademecum && <MedicineInfoPopOver
                                                                idVademecum={pres.idVademecum}
                                                                visible={true}
                                                                flagDetallePorBaja={pres.flagDetallePorBaja}
                                                                setMedicine={this.props.setMedicine}
                                                            />}
                                                        </span>
                                                    </div>
                                                    <div>
                                                        {pres.textoPosology && <span style={{paddingRight: '1rem'}}>{pres.textoPosology}</span>}
                                                    </div>
                                                    {pres.descIndicacionesPaciente && <div><b style={{paddingRight: '1rem'}}>{this.props.t('medicineConfig:posologySelectedPatientIndications')}</b> {pres.descIndicacionesPaciente}</div>}
                                                    {pres.descIndicacionesFarmaceutico && <div><b style={{paddingRight: '1rem'}}>{this.props.t('medicineConfig:posologySelectedPharmacyIndications')}</b> {pres.descIndicacionesFarmaceutico}</div>}
                                                </div>
                                            </div>
                                        </div>
                                        {!this.props.pending && pres.recetas &&
                                            <PrescriptionReceipts
                                                recetas={pres.recetas}
                                                idPrescription={pres.idPrescripcion}
                                                prescripcionEstado={pres.typeEstado}
                                                reactivatePrescription={this.props.reactivatePrescription}
                                                voidPrescription={this.props.voidPrescription}
                                                consultReplace={this.props.consultReplace} 
                                                adherencia={pres.adherencia}/>
                                        }
                                    </div>
                                </Col>
                            )

                        })}
                        {this.props.pending &&
                            <Col span={24}>
                                <Row align='middle' className='data_container'>
                                    <div className='number'>{this.props.prescriptions ? this.props.prescriptions.length + prescripcionesFirstIndex : prescripcionesFirstIndex}</div>
                                    <span className='text_title'>
                                        {this.props.t(this.props.prescriptions && this.props.prescriptions.length > 0 ? 'anotherPrescriptionPosologyTitle' : 'prescriptionPosologyTitle')}
                                    </span>
                                    <Dropdown overlay={receiptMenu} visible={this.props.receiptMenuVisible} trigger={['click']}
                                         onVisibleChange={(visible: boolean) => {if(visible==false){this.props.onCloseReceiptMenu()}}}>
                                        <Button type="link" className='link_title' onClick={this.props.controlPsychotropicNarcotic}>{this.props.t('recipeButton')}</Button>
                                    </Dropdown>
                                </Row>
                            </Col>
                        }

                        {vidalEnabled && <Col span={24} style={{display:"flex"}}>
                            <PrescriptionAlertsPanelContainer
                            visible={true}
                            idPrescriptionGroup={this.props.idPrescriptionGroup}
                            pending={this.props.pending}
                            calledFrom={"newPrescription"}
                            refresh={this.props.alertsRefresh}
                            level={1}
                            onCriticVidalAlert={this.onCriticVidalAlert}
                            />
                        </Col>}
                        {this.state.informeVisado && <InformeVisadoMuface {...this.state.informeVisado} onClose={this.onCloseInformeVisado} />}
                    </Row>
                </div>
            </div>
            </>
        );
    }

    private onCriticVidalAlert = (critic?: boolean) => {
            console.log("Usa funcion critic vidal alerts "+critic);
            if(critic){
                this.setState({criticAlerts: true})
            }else{
                this.setState({criticAlerts: false})
            }
    }

    private onClickReceiptMenu = (e: any) => {
        this.props.onCloseReceiptMenu();
        if(e.key==7){
           this.props.onClickPrescriptionFavList();
        }else{
            this.props.onClickPrescriptionConfig(e.key);
        }

    }

    private getVisadoStatus = (type?: number, denegacionTipo?: number, desDenegacion?: string) => {

        let className = '';
        let title = '';
        let statusLabel = this.props.t('visadoStatuses:' + type);
        let icon;
        let tooltip;

        if (type === 2) {
            className = 'pending';
        }
        if (type === 3) {
            className = 'authorized';
        }
        if (type === 4) {
            className = 'denied';
            title = this.props.t('visaDeniedTooltipLabel');
            icon = faExclamationTriangle;
            tooltip = <div style={{display:'flex', flexDirection:"column"}}>
                        <span style={{fontWeight:'bold'}}>{title}</span>
                        <span>{this.props.t('denegacionType:' + denegacionTipo!)+"."+desDenegacion}</span>
            </div>;
        }

        return (
            <Tooltip color={"gray"} placement={"bottom"} title={tooltip}>
                <span className={'visa-status ' + className}>   
                  <span className='visa-v'>V</span>{statusLabel}{icon!=undefined && <FontAwesomeIcon icon={icon} style={{color: 'yellow',marginLeft: '6px'}}/>}
                </span>
            </Tooltip>

        );
    }

    private getPrescriptionStatus = (type: number, dateEmision?: Date, dateAnulacion?: Date) => {

        let className = '';
        let title = '';
        let icon = faCheckCircle;
        let tooltip = '';

        if (type === EnumPrescriptionStatus.PTE_FIRMA) {
            className = 'pending';
            title = this.props.t('pendingPrescriptionLabel');
            icon = faExclamationCircle;
        }
        if (type === EnumPrescriptionStatus.EMITIDA) {
            className = 'emitted';
            title = this.props.t('emittedPrescriptionLabel');
            icon = faCheckCircle;
            tooltip = this.props.t('emittedTooltip', {fecha: Functions.dateToString(dateEmision)});
        }
        if (type === EnumPrescriptionStatus.ANULADA) {
            className = 'cancelled';
            title = this.props.t('cancelledPrescriptionLabel');
            icon = faTimesCircle;
            tooltip = this.props.t('cancelledTooltip', {fecha: Functions.dateToString(dateAnulacion)});
        }

        return (
            <Tooltip title={tooltip}>
                <span className={'prescription-status ' + className}>   
                    <FontAwesomeIcon icon={icon} style={{marginRight: '6px'}}/> {title}
                </span>
            </Tooltip>

        );
    }

    mostrarInformeVisado = (idPrescripcion: number, nombreProducto: string) => {
        this.setState({informeVisado: {
            key: new Date().getTime(),
            visible: true,
            idPrescripcion,
            nombreProducto
        }});
    }

    onCloseInformeVisado = () => {
        this.setState({informeVisado: {...this.state.informeVisado!, visible: false}});
    }

}

export default withTranslation('prescription')(withRouter(NewPrescription));