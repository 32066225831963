import React, {ReactNode} from 'react';

import {WithTranslation, withTranslation} from 'react-i18next';
import {withRouter, RouteComponentProps} from 'react-router-dom';
import {Modal, notification} from 'antd';
import {concatName, getPrescriptionTypeIcon, Rest} from '../utils/utils';
import View from './newPrescription';
import PatientData from './patient/entities/patientData';
import PersonaSelected from './patient/entities/personaSelected';
import SelectLocationContainer from './locations/selectLocationContainer';
import {LocationPrestador} from './locations/entities/locationPrestador';
import NewLocationContainer from './locations/newLocationContainer';
import PatientDataDetails from './patient/entities/patientDataDetails';
import LocalSaveSingContainer from './saveSing/localSaveSignContainer';
import SavePrescriptionContainer from './savePrescription/SavePrescriptionContainer';
import PrescripcionPosologyInfo from './entities/prescripcionPosologyInfo';
import _ from 'lodash';
import PatientCommonContainer from './patient/PatientCommonContainer';
import EnumPrescriptionStatus from '../enum/EnumPrescriptionStatus';
import EmittedPrescriptionActionsContainer from './emittedPrescriptionActions/EmittedPrescriptionActionsContainer';
import EnumEmittedPrescriptionAction from '../enum/EnumEmittedPrescriptionAction';
import MenuConfig, {COMPONENT_IDS} from '../layout/menuConfig';
import {TabExtendedProps} from '../MainLayout';
import NewPrescriptionsContainer from './newPrescriptionsContainer';
import WaitingConsentContainer from '../consent/WaitingConsentContainer';
import PrescripcionesAnterioresContainer from './anteriores/PrescripcionesAnterioresContainer';
import EnumPrescriptionType from '../enum/EnumPrescriptionType';
import AditionalPatientInfoContainer from './patient/aditionalPatientInfoContainer';
import PatologiesSelectContainer from './patient/patologiesSelectContainer';
import { PatologiaData } from './patient/entities/patologiaData';
import PrestadorConfigContext from '../context/PrestadorConfigContext';
import { PrescriptionAlertsProvider } from '../context/PrescriptionAlertsContext';
import FavPrescriptionListContainer from './favoritePrescriptions/FavPrescriptionListContainer';
import PrescripcionAnterior from './anteriores/entities/PrescripcionAnteriorData';


interface IProps {
    idPrescripcionGrupo?: number;
    idPaciente?: number;
    pending: boolean;
    fixedPatientLocation?: boolean;
    hideOptions?: boolean;
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    closeTab?: () => void;
    refresh?: () => void;
}

interface WaitingConsentPersonData {
    idPersona: number;
    personaName: string;
    personaMail: string;
    token: string;
    typeConsentimiento?: number;
    callback?: () => void;
}


export interface IState {
    loaded?: boolean;

    patientKey?: number;
    patientContainer?: boolean;
    editPatient?: boolean;
    patientSelected?: PatientData;
    flagPrestadorHabilitado?: boolean;
    flagPacienteMutualista?: boolean;

    selectLocationKey?: number;
    newLocationVisible?: boolean;
    newLocationKey?: number;
    saveSignVisible?: boolean;
    saveSignKey?: number;
    receiptMenuVisible?: boolean;
    modeNewLocation?: boolean;
    prescriptionConfigVisible?: boolean;
    prescriptionConfigKey?: number;
    prescriptionType?: number;
    idPrescriptionGroup?: number;
    prescriptions?: PrescripcionPosologyInfo[];
    idPrescriptionMod?: number;
    isTutorConsentimiento?: boolean;
    actionsKey?: number;
    actionsVisible?: boolean;
    actionType?: number;

    aditionalPatientInfoKey?: number;
    aditionalPatientInfoContainer?: boolean;

    waitingPrestadorKey?: number;
    waitingPrestadorVisible?: boolean;
    waitingData?: WaitingConsentPersonData;

    prestacionesAnterioresKey?: number;
    prestacionesAnterioresVisible?: boolean;

    prescriptionFavListVisible?: boolean;
    prescriptionFavListKey?: number;


    //
    idSelectedLocation?: number;
    selectLocationVisible?: boolean;
    selectedLocation?: LocationPrestador;
    locationForEdit?: LocationPrestador;

    diagnosticoSelected?: boolean;
    diagnosticoSelectedCode?:string;
    diagnosticoSelectedName?: string;
    diagnosticoSelectedNombre?: string;
    selectDiagnosticoKey?: number;
    selectDiagnosticoVisible?: boolean;
    diagnosticoSelectedId?:number;

    alertsRefresh: number;

    prescripcionesActivas?: PrescripcionAnterior[];
    prescripcionesActivasVisible?: boolean;

    hasPsychotropicNarcotic?: boolean;
}

class NewprescriptionsContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    state: IState = {
        selectLocationVisible: false,
        aditionalPatientInfoContainer: false,
        prescriptionFavListVisible:false,
        alertsRefresh: 1,
    };

    static contextType = PrestadorConfigContext;
    
    public componentDidMount() {
        if (this.props.idPrescripcionGrupo) {
            this.loadData();
        } else if (this.props.idPaciente) {
            this.showSelectedPatientDataDetails(this.props.idPaciente);
            Rest<{ type: string }, LocationPrestador>()
                .operation({type: 'GetPrescripcionLocationDataSelected'})
                .then(response => {
                    this.setState({
                        selectedLocation: response,
                        idSelectedLocation: response?.idLocalizacion
                    });
                });
        } else if (this.props.fixedPatientLocation) {

            this.showSelectedPatientDataDetails();

            Rest<{ type: string }, LocationPrestador>()
                .operation({type: 'GetPrescripcionLocationDataSelected'})
                .then(response => {
                    this.setState({selectedLocation: response, idSelectedLocation: response.idLocalizacion});
                });

        } else {
            Rest<{ type: string }, LocationPrestador>()
                .operation({type: 'GetPrescripcionLocationDataSelected'})
                .then(response => {
                    this.setState({
                        selectedLocation: response,
                        idSelectedLocation: response?.idLocalizacion,
                        loaded: true,
                    });
                });
        }
    }


    /////////////////////////////////////////////////////////////////

    onSelectLocation = (selectedLocation: LocationPrestador) => {
            this.setState({
                idSelectedLocation: selectedLocation.idLocalizacion, selectLocationVisible: false,
                selectedLocation: selectedLocation,
            }, () => this.modifyPatientOrLocationPrescription());
    };


    onEditLocation = (location: LocationPrestador) => {
        this.setState({
            newLocationVisible: true, modeNewLocation: false, locationForEdit: location,
            newLocationKey: new Date().getTime(), selectLocationVisible: false,
        });
    };

    private onClickSelectLocation = () => {
        this.setState({
            selectLocationVisible: true,
            selectLocationKey: new Date().getTime(),
            idSelectedLocation: this.state.idSelectedLocation,
        });
    };

    private onCloseSelectLocation = () => {
        this.setState({selectLocationVisible: false});
    };


    private newLocation = () => {
        this.setState({
            locationForEdit: undefined,
            selectLocationVisible: false,
            newLocationVisible: true,
            newLocationKey: new Date().getTime(),
            modeNewLocation: true,
        });
    };

    private onCloseNewLocation = () => {
        this.setState({
            selectLocationVisible: this.state.modeNewLocation,
            selectLocationKey: new Date().getTime(),
            newLocationVisible: false,
        });
    };


    private addOrUpdateLocation = () => {
        this.onCloseNewLocation();
    };

    /////////////////////////////////////////////////////////////////


    public render() {
        return (
            <PrescriptionAlertsProvider>
                {this.state.loaded &&
                        <View
                            {...this.state}
                            pending={this.props.pending}
                            fixedPatientLocation={this.props.fixedPatientLocation}
                            hideOptions={this.props.hideOptions}
                            onClickSelectPatient={this.onClickSelectPatient}
                            onClickPrescripcionesAnteriores={this.onClickPrescripcionesAnteriores}
                            onClickChangePatient={this.onClickChangePatient}
                            onClickSelectLocation={this.onClickSelectLocation}
                            onClickChangeLocation={this.onClickSelectLocation}
                            onClickPatientDetails={this.onClickPatientDetails}
                            onClickSaveSign={this.onClickSaveSign}
                            onClickPrescriptionConfig={this.onClickPrescriptionConfig}
                            onClickPrescriptionFavList={this.onClickPrescriptionFavList}
                            onClickLocationDetails={this.onEditLocationDetails}
                            onClickAditionalPatientInfo={this.onClickAditionalPatientInfo}
                            deletePrescription={this.deletePrescription}
                            modifyPrescription={this.modifyPrescription}
                            closeTab={this.close}
                            cancelPrescriptions={this.cancelPrescriptions}
                            reactivatePrescription={this.reactivatePrescription}
                            voidPrescription={this.voidPrescription}
                            onClickEmittedActions={this.onClickEmittedActions}
                            getPrescriptionTypeIcon={getPrescriptionTypeIcon}
                            consultReplace={this.consultReplace}
                            onClickSelectDiagnostico={this.onClickSelectDiagnostico}
                            deleteDiagnostico={this.deleteDiagnostico}
                            onClickChangeDiagnostico={this.onClickSelectDiagnostico}
                            setMedicine={this.setMedicine}
                            setPrescriptionesAnteriores={this.setPrescriptionesAnteriores}
                            controlPsychotropicNarcotic={this.controlPsychotropicNarcotic}
                            onCloseReceiptMenu={this.onCloseReceiptMenu}
                            onClickButtonAddFavorite={this.onClickButtonAddFavorite}
                        />
                }

                <PatientCommonContainer
                    key={this.state.patientKey}
                    visible={this.state.patientContainer}
                    onClose={() => this.setState({patientContainer: false})}
                    addPatientSelected={this.showSelectedPatientDataDetails}
                    idPatient={this.state.patientSelected?.idPaciente}
                    patientSelected={this.state.patientSelected}
                    editPatient={this.state.editPatient}
                    editPatientSelectedWithoutMail={this.editPatientSelectedWithoutMail}
                />

                <SelectLocationContainer
                    key={this.state.selectLocationKey}
                    visible={this.state.selectLocationVisible}
                    onClose={this.onCloseSelectLocation}
                    newLocation={this.newLocation}
                    onSelectLocation={this.onSelectLocation}
                    idSelectedLocation={this.state.idSelectedLocation}
                    onEditLocation={this.onEditLocation}
                />

                {
                    <NewLocationContainer
                        key={this.state.newLocationKey}
                        visible={this.state.newLocationVisible}
                        modeNewLocation={this.state.modeNewLocation ? this.state.modeNewLocation : false}
                        locationForEdit={this.state.locationForEdit}
                        addNewLocation={this.addOrUpdateLocation}
                        onClose={this.onCloseNewLocation}
                        reloadSelectedLocation={this.reloadSelectedLocation}
                    />}

                {/*<SaveSingContainer
                    key={this.state.saveSignKey}
                    visible={this.state.saveSignVisible}
                    onClose={this.onCloseSignPassword}
                    idPrescripcionGrupo={this.state.idPrescriptionGroup!}
                />*/}

                <LocalSaveSingContainer
                    key={this.state.saveSignKey}
                    visible={this.state.saveSignVisible}
                    onClose={this.onCloseSignPassword}
                    idPrescripcionGrupo={this.state.idPrescriptionGroup!}
                    idPaciente={this.state.patientSelected?.idPaciente}
                    emailPaciente={this.state.patientSelected?.valueEmail}
                />

                <SavePrescriptionContainer
                    key={this.state.prescriptionConfigKey}
                    visible={this.state.prescriptionConfigVisible}
                    idPatient={this.state.patientSelected?.idPaciente}
                    idLocation={this.state.idSelectedLocation}
                    idDiagnostico={this.state.diagnosticoSelectedId}
                    codeDiagnostico={this.state.diagnosticoSelectedCode}
                    typePrescription={this.state.prescriptionType}
                    idPrescriptionGroup={this.state.idPrescriptionGroup}
                    idPrescriptionMod={this.state.idPrescriptionMod}
                    setMedicine={this.setMedicine}
                    flagPacienteMutualista={this.state.flagPacienteMutualista}
                    onClose={this.onClosePrescriptionConfig}/>

                <EmittedPrescriptionActionsContainer
                    key={this.state.actionsKey}
                    visible={this.state.actionsVisible}
                    idPrescriptionGroup={this.state.idPrescriptionGroup}
                    action={this.state.actionType}
                    onClose={this.onCloseActions}/>

                <WaitingConsentContainer
                    key={this.state.waitingPrestadorKey}
                    visible={this.state.waitingPrestadorVisible}
                    idPersona={this.state.waitingData?.idPersona!}
                    patientName={this.state.waitingData?.personaName!}
                    patientMail={this.state.waitingData?.personaMail!}
                    token={this.state.waitingData?.token}
                    onClose={this.onCloseWaitingModal}
                    checkConsentimiento={this.checkPatientConsentimiento}/>

                <PrescripcionesAnterioresContainer
                    key={this.state.prestacionesAnterioresKey}
                    visible={this.state.prestacionesAnterioresVisible}
                    patientSelected={this.state.patientSelected}
                    getPrescriptionTypeIcon={getPrescriptionTypeIcon}
                    onClose={this.onClosePrescripcionesAnteriores}
                    setPrescriptionesAnteriores={this.setPrescriptionesAnteriores}
                />
                {this.state.patientSelected &&<AditionalPatientInfoContainer
                    key={this.state.aditionalPatientInfoKey}
                    visible={this.state.aditionalPatientInfoContainer}
                    patientSelected={this.state.patientSelected!.idPaciente!}
                    onClose={this.onCloseAditionalPatientInfo}
                />}

                {<Modal title={""}
                   className= {"aditionalInfoModal"}
                   style={{top: 40}}
                   visible={this.state.selectDiagnosticoVisible}
                   onCancel={this.onCancelSelectDiagnostico}
                   bodyStyle={{paddingTop: '0.5rem', paddingBottom: '1.5rem'}}
                   width={700}
                   okText={this.props.t('buttons:save')} cancelText={this.props.t('buttons:cancel')}
                   destroyOnClose
                   okButtonProps={{ style: { display: 'none' }}}
                >
                    <PatologiesSelectContainer
                        key={this.state.selectDiagnosticoKey}
                        fromDiagnostico={true}
                        visible={this.state.selectDiagnosticoVisible} 
                        onClose={this.onCloseSelectDiagnostico}
                    />
                </Modal>
                }
                {<Modal
                    key={this.state.prescriptionFavListKey}
                    visible={this.state.prescriptionFavListVisible}
                    destroyOnClose
                    closable
                    title={this.props.t('prescriptionFavList')}
                    onCancel={() => this.onClosePrescriptionFavList(-1)}
                    style={{ top: 40 }}
                    width={'1200px'}
                    footer={null}
                >
                    <FavPrescriptionListContainer visible={this.state.prescriptionFavListVisible} fromNewPrescription={true} onSelectFav={this.onSelectFav}/>
                </Modal>}
                
            </PrescriptionAlertsProvider>
        );
    }

    private onClickSelectDiagnostico = () => {
        this.setState({selectDiagnosticoVisible: true, selectDiagnosticoKey: new Date().getTime()})
    }
    private onCancelSelectDiagnostico = () => {
        this.setState({selectDiagnosticoVisible: false});
    }

    private changeDiagnostico = (idDiagnostico?: number) => {
        Rest<{ type: string, idPrescripcionGrupo: any, idDiagnostico: any}, any>().operation({
            type: 'ActualizarDiagnostico',
            idPrescripcionGrupo: this.state.idPrescriptionGroup,
            idDiagnostico: idDiagnostico
        }).then(() => 
            this.setState({alertsRefresh: this.state.alertsRefresh+1})
        );
        
    }
    private deleteDiagnostico = () => {
        if(this.state.idPrescriptionGroup!=null && this.state.idPrescriptionGroup!=undefined){
            Rest<{ type: string, idPrescripcionGrupo: any, idDiagnostico: any}, any>().operation({
                type: 'ActualizarDiagnostico',
                idPrescripcionGrupo: this.state.idPrescriptionGroup,
                idDiagnostico: null,
            }).then(() => {
                
                this.setState({alertsRefresh: this.state.alertsRefresh+1});
                
                this.setState({diagnosticoSelected:false, diagnosticoSelectedId:undefined,diagnosticoSelectedCode:undefined, diagnosticoSelectedName:undefined,diagnosticoSelectedNombre:undefined});
            });
        }else{
            this.setState({diagnosticoSelected:false, diagnosticoSelectedId:undefined, diagnosticoSelectedCode:undefined,diagnosticoSelectedName:undefined,diagnosticoSelectedNombre:undefined});
        }
        
    }
    private onCloseSelectDiagnostico = (patologie?:PatologiaData) => {
        if(patologie!=undefined && patologie!= null){
            var nombre = patologie.codeCie10 +" "+ patologie.nameCie10Es;
            var name = patologie.codeCie10+" "+patologie.nameCie10En;
            var idDiagnostico = patologie.idCie10;
            var code = patologie.codeCie10;
            this.setState({diagnosticoSelected:true,diagnosticoSelectedId:idDiagnostico,diagnosticoSelectedCode: code,diagnosticoSelectedNombre:nombre,diagnosticoSelectedName:name});
            if(this.state.idPrescriptionGroup!=null && this.state.idPrescriptionGroup!=undefined){
                this.changeDiagnostico(idDiagnostico);
            }
        }
        this.setState({selectDiagnosticoKey:undefined, selectDiagnosticoVisible: false});
    }
    private editPatientSelectedWithoutMail = (selectedPatient: PersonaSelected) => {
        this.setState({
            patientSelected: selectedPatient,
            editPatient: true,
            loaded: true,
            patientContainer: true,
            patientKey: new Date().getTime(),
        });
    };

    private onCloseWaitingModal = (idPersona: number, acepted?: boolean) => {
        this.setState({waitingPrestadorVisible: false}, () => {
            if (acepted && this.state.waitingData?.callback) {
                this.state.waitingData?.callback();
            }
        });
    };

    private openWaitingModal = (waitingData: WaitingConsentPersonData) => {
        this.setState({
            waitingData: waitingData,
            waitingPrestadorVisible: true,
            waitingPrestadorKey: new Date().getTime(),
        });
    };

    private checkPatientConsentimiento = (idPatient: number, callback?: () => void) => {
        Rest<{ type: string, idPersona: number }, any>().operation({
            type: 'CheckConsentimientoPersona',
            idPersona: idPatient,
        }).then(response => {
            if (response.token) {
                this.openWaitingModal({
                    idPersona: idPatient,
                    personaName: response.personaName,
                    personaMail: response.personaMail,
                    token: response.token,
                    typeConsentimiento: 2,
                    callback,
                });
            } else {
                callback && callback();
            }
        });
    };


    private close = () => {

        if (this.props.closeTab) {
            this.props.closeTab();
        }
    };

    private loadData = () => {
        Rest<{ type: string; idPrescripcionGrupo: number,pendiente:boolean }, any>()
            .operation({
                type: 'GetPrescriptionDetails',
                idPrescripcionGrupo: this.props.idPrescripcionGrupo!,
                pendiente:this.props.pending,
        
            })
            .then(response => {
                this.setState({alertsRefresh: this.state.alertsRefresh+1});
                response.paciente.namePersona = concatName(response.paciente.nameNombre, response.paciente.nameApellido1, response.paciente.nameApellido2);
                const code = response.diagnostico ? response.diagnostico.codeCie10 : undefined;
                const id = response.diagnostico ? response.diagnostico.idCie10 : undefined;
                const name = response.diagnostico ? (response.diagnostico.codeCie10 + ' ' + response.diagnostico.nameCie10En) : '';
                const nombre = response.diagnostico ? (response.diagnostico.codeCie10 + ' ' + response.diagnostico.nameCie10Es) : '';
                this.setState({
                    patientSelected: response.paciente,
                    flagPrestadorHabilitado: response.flagPrestadorHabilitado,
                    flagPacienteMutualista: response.flagPacienteMutualista,
                    selectedLocation: response.localizacion,
                    idSelectedLocation: response.localizacion.idLocalizacion,
                    prescriptions: this.setPrescriptions(response.prescripciones? response.prescripciones.map((e: any, i: number) => {
                        e.key = i;
                        return e;
                        }) : undefined,
                        response.recetas?
                        response.recetas.map((e: any, i: number) => {
                            e.key = i;
                            return e;
                    } ) : undefined) ,
                    idPrescriptionGroup: response.idPrescripcionGrupo,
                    diagnosticoSelected: response.diagnostico ? true: false,
                    diagnosticoSelectedId: id,
                    diagnosticoSelectedCode: code,
                    diagnosticoSelectedNombre: nombre,
                    diagnosticoSelectedName: name,
                    loaded:true
                });
            }).then(()=> 
            this.getPrescripcionesActivas())
            
           

    };

    private setMedicine = (medicine: any) => {
        return {
            idVademecum: medicine[0].idVademecum,
            flagDetallePorBaja: medicine[0].flagDetallePorBaja,
            codeCodigoNacional: medicine[0].codeCodigoNacional ?  medicine[0].codeCodigoNacional :  medicine[0].cn,
            nameProducto:medicine[0].nameProducto ? medicine[0].nameProducto : medicine[0].name,
            laboratory: medicine[0].laboratory,
            dosage: medicine[0].dosage,
            psychotropic: medicine[0].psychotropic,
            narcotic: medicine[0].narcotic,
            codePharmaceuticalForm: medicine[0].codePharmaceuticalForm,
            namePharmaceuticalForm: medicine[0].namePharmaceuticalForm,
            codeUnitContent: medicine[0].codeUnitContent,
            valueUnitContent: medicine[0].valueUnitContent,
            codeDcpf: medicine[0].codeDcpf,
            nameDcpf: medicine[0].nameDcpf,
            activePrinciples: medicine[0].codeActivePrinciple && _(medicine).groupBy('codeActivePrinciple').map((value) => ({ codeActivePrinciple: value[0].codeActivePrinciple, nameActivePrinciple: value[0].nameActivePrinciple})).value(),
            vias: _(medicine).groupBy('codeVia').map((value) => ({ codeVia: value[0].codeVia, nameVia: value[0].nameVia})).value(),
            docs: medicine[0].idDocument && _(medicine).groupBy('idDocument').map((value) => ({ idDocument: value[0].idDocument, urlDocument: value[0].urlDocument})).value(),
            photos: medicine[0].idImage && _(medicine).groupBy('idImage').map((value) => ({ idImage: value[0].idImage, image: value[0].image})).value()
        }
    }

    private onClickSelectPatient = () => {
        this.setState({patientContainer: true, patientKey: new Date().getTime()});
    };

    private onClickChangePatient = () => {
        this.setState({
            editPatient: false,
            patientContainer: true,
            patientKey: new Date().getTime(),
        });
    };

    private onClickPatientDetails = () => {
        this.setState({patientContainer: false});
        this.setState({
            editPatient: true,
            patientContainer: true,
            patientKey: new Date().getTime(),
        });
    };

    private showSelectedPatientDataDetails = (idPatient?: number, consent?: boolean) => {
        Rest<{ type: string, idPaciente?: number }, PatientDataDetails>()
            .operation({
                type: 'GetPatientDataDetails',
                idPaciente: idPatient,
            }).then(response => {
            if (response) {
                response.patientData.namePersona = concatName(response.patientData.nameNombre, response.patientData.nameApellido1, response.patientData.nameApellido2);
                this.setState({
                    patientSelected: response.patientData,
                    flagPrestadorHabilitado: response.flagPrestadorHabilitado,
                    flagPacienteMutualista: response.flagPacienteMutualista,
                    loaded: true,
                }, () => {
                    if (consent) {
                        notification['success']({
                            message: this.props.t('patientAllowed'),
                        });
                    }

                    if (this.state.prescriptions && this.state.prescriptions.length > 0) {
                        this.modifyPatientOrLocationPrescription();
                    }
                });
                this.getPrescripcionesActivas();
            } else if (idPatient) {
                this.checkPatientConsentimiento(idPatient);
            } else {
                throw new Error('Unexpected patient data for new prescription');
            }
        });
    };


    private onClickSaveSign = () => {

        if (this.state.prescriptions?.some(pres => pres.dateInicioTratamiento.setHours(0, 0, 0, 0) < new Date().setHours(0, 0, 0, 0))) {
            notification['error']({
                message: this.props.t('iniTreatmentError'),
            });

            return;
        }

        this.setState({
            saveSignVisible: true,
            saveSignKey: new Date().getTime(),
        });
    };

    private onCloseSignPassword = () => {
        this.setState({
            saveSignVisible: false,
        }, () => {
            (this.props.refresh && this.props.refresh());
            (this.props.closeTab && this.props.closeTab());
            this.maybeNotifyParentWindow();
        });
    };

    private onClickReceiptConfiguration = () => {
        if (this.state.patientSelected && this.state.selectedLocation) {
            this.setState({receiptMenuVisible: true});
        }
    };

    private onCloseReceiptMenu = () => {
        this.setState({receiptMenuVisible: false});
    }

    private onClickPrescriptionConfig = (prescriptionType: number) => {
        if (!this.state.patientSelected || !this.state.selectedLocation) {
            return;
        }

        this.setState({prescriptionConfigVisible: true, prescriptionConfigKey: new Date().getTime(), prescriptionType});
    };

    private onClosePrescriptionConfig = (idPrescriptionGroup?: number) => {
        this.setState({
            prescriptionConfigVisible: false,
            prescriptionType: undefined,
            idPrescriptionGroup: idPrescriptionGroup ? idPrescriptionGroup : this.state.idPrescriptionGroup,
            idPrescriptionMod: undefined,
        }, () => {
            if (idPrescriptionGroup) {
                this.loadPrescriptionData();
            }
        });
    };

    private loadPrescriptionData = (fromDeleteLast?: boolean) => {
        Rest<{ type: string, idPrescripcionGrupo: number, typeEstado?: number[] }, PrescripcionPosologyInfo[]>()
            .operation({
                type: 'SelectPrescriptionsDataByGroup',
                idPrescripcionGrupo: this.state.idPrescriptionGroup!,
                typeEstado: [EnumPrescriptionStatus.PTE_FIRMA],
            }).then(response => {
            this.setState({prescriptions: this.setPrescriptions(response),alertsRefresh: fromDeleteLast ? this.state.alertsRefresh+2 : this.state.alertsRefresh+1});
        });
    };

    private setPrescriptions = (prescriptions: any, recetas?: any) => {
        return _(prescriptions)
            .groupBy('idPrescripcion')
            .map((value) => ({
                idPrescripcion: value[0].idPrescripcion,
                typeProducto: value[0].typeProducto,
                typeEstado: value[0].typeEstado,
                typeStatusVisado: value[0].typeStatusVisado,
                typePosologia: value[0].typePosologia,
                typeDenegacionVisado: value[0].typeDenegacionVisado,
                descDenegacionVisado: value[0].descDenegacionVisado,
                idVademecum: value[0].idVademecum,
                packageId: value[0].packageId,
                flagDetallePorBaja: value[0].flagDetallePorBaja,
                valueComposicionFmv: value[0].valueComposicionFmv,
                valuePresentacion: value[0].valuePresentacion,
                codeCodigoNacional: value[0].codeCodigoNacional,
                codeCodigoNacionalPres: value[0].codeCodigoNacionalPres,
                valueDosis: value[0].valueDosis,
                valueUnidadContenido: value[0].valueUnidadContenido,
                typeFrecuencia: value[0].typeFrecuencia,
                valueFrecuencia: value[0].valueFrecuencia,
                typeDuracion: value[0].typeDuracion,
                valueDuracion: value[0].valueDuracion,
                dateInicioTratamiento: value[0].dateInicioTratamiento,
                descIndicacionesPaciente: value[0].descIndicacionesPaciente,
                descIndicacionesFarmaceutico: value[0].descIndicacionesFarmaceutico,
                codeConfidencial: value[0].codeConfidencial,
                flagVisado: value[0].flagVisado,
                dateIniVisado: value[0].dateIniVisado,
                dateFinVisado: value[0].dateFinVisado,
                pctRegAportacion: value[0].pctRegAportacion,
                recetas: recetas && recetas.filter((rec: any) => rec.receta.idPrescripcion === value[0].idPrescripcion),
                dateEmision: value[0].dateEmision,
                dateAnulacion: value[0].dateAnulacion,
                codeDcpf: value[0].codeDcpf,
                valueDuracionEnvaseDias: value[0].valueDuracionEnvaseDias,
                nbrEnvasesTotal: value[0].nbrEnvasesTotal,
                nameDcpf: value[0].nameDcpf,
                flagMutualista: value[0].flagMutualista,
                adherencia: value[0].adherencia,
                typePosologiaEspecial: value[0].typePosologiaEspecial,
                idUnidad: value[0].idUnidad,
                textoPosology: value[0].textoPosology,
                photos: value[0].idVademecumImagen && _(value)
                    .groupBy('idVademecumImagen')
                    .map((value) => ({
                        idVademecumImagen: value[0].idVademecumImagen,
                        valueImagen: value[0].valueImagen,
                    })).value(),
            })).value() as PrescripcionPosologyInfo[];
    };


    private onEditLocationDetails = () => {
        this.setState({modeNewLocation: false, newLocationVisible: true, selectLocationVisible: false,
            locationForEdit:  this.state.selectedLocation,
        });
    };

    private reloadSelectedLocation = (idLocalizacion: number) => {
        Rest<{ type: string, idLocalizacion: number }, LocationPrestador>()
            .operation({
                type: 'GetLocationDataSelected',
                idLocalizacion: idLocalizacion,
            }).then(response => {
            if (idLocalizacion === this.state.idSelectedLocation) {
                this.setState({selectedLocation: response, newLocationVisible: false});
            } else {
                this.setState({newLocationVisible: false});
            }
        });
    };

    private deletePrescription = (idPrescription: number) => {
        
        Rest<{ type: string, idPrescripcion: number }, any>()
            .operation({
                type: 'DeletePrescription',
                idPrescripcion: idPrescription,
            }).then(() => {
                if (this.state.prescriptions && this.state.prescriptions.length > 1) {
                    this.loadPrescriptionData();
                }else{
                    this.loadPrescriptionData(true);
                }
        });
    };

    private modifyPatientOrLocationPrescription = () => {
        if(this.state.idPrescriptionGroup === undefined) { //Refresh data only for existing prescripción
            return;
        }
        Rest<{ type: string, idPrescripcionGrupo: number, idLocation: number, idPatient: number }, any>()
            .operation({
                type: 'ModifyPatientOrLocationPrescription',
                idPrescripcionGrupo: this.state.idPrescriptionGroup!,
                idLocation: this.state.selectedLocation?.idLocalizacion!,
                idPatient: this.state.patientSelected?.idPaciente!,
        }).then(()=>{
                
                this.setState({alertsRefresh: this.state.alertsRefresh+1});
                
            }
        );

    };

    private modifyPrescription = (idPrescription: number) => {

        const prescription = this.state.prescriptions?.find(pres => pres.idPrescripcion === idPrescription);
        let prescriptionType = prescription?.typeProducto;
        
        if ((prescription?.codeCodigoNacional || prescription?.codeDcpf) && prescription.typeProducto === EnumPrescriptionType.VACUNA) {
            prescriptionType = EnumPrescriptionType.VACUNA_CATALOGADA;
        }
        if ((prescription?.codeCodigoNacional || prescription?.codeCodigoNacionalPres || prescription?.codeDcpf) && prescription.typeProducto === EnumPrescriptionType.FORMULA_MAGISTRAL) {
            prescriptionType = EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA;
        }
        if ((prescription?.codeCodigoNacional || prescription?.codeCodigoNacionalPres || prescription?.codeDcpf || prescription?.packageId) && prescription.typeProducto === EnumPrescriptionType.NUTRICION) {
            prescriptionType = EnumPrescriptionType.NUTRICION_CATALOGADA;
        }
        if ((prescription?.codeCodigoNacional || prescription?.codeCodigoNacionalPres) && prescription?.typeProducto === EnumPrescriptionType.PRODUCTO_SANITARIO) {
            prescriptionType = EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO;
        }

        this.setState({
            prescriptionConfigVisible: true,
            prescriptionConfigKey: new Date().getTime(),
            idPrescriptionMod: idPrescription,
            prescriptionType,
        });
    };

    private cancelPrescriptions = () => {
        if (!this.state.idPrescriptionGroup) {
            this.props.closeTab && this.props.closeTab();
            return;
        }

        Rest<{ type: string, idPrescripcionGrupo: number }, any>()
            .operation({
                type: 'CancelPrescriptions',
                idPrescripcionGrupo: this.state.idPrescriptionGroup!,
            }).then(() => {
            this.props.closeTab && this.props.closeTab();
        });

    };

    private reactivatePrescription = (idPrescription: number) => {
        Rest<{ type: string, idPrescripcion: number }, any>()
            .operation({
                type: 'ReactivatePrescription',
                idPrescripcion: idPrescription,
            }).then(() => {
            this.loadData();
        });
    };

    private voidPrescription = (idPrescription: number) => {
        Rest<{ type: string, idPrescripcion: number }, any>()
            .operation({
                type: 'VoidPrescription',
                idPrescripcion: idPrescription,
            }).then(() => {
            this.loadData();
        });
    };

    private onClickEmittedActions = (action: number) => {
        this.setState({actionsKey: new Date().getTime(), actionsVisible: true, actionType: action});
    };

    private onCloseActions = (action?: number, idPrescripcionGrupo?: number) => {
        this.setState({actionsVisible: false, actionType: undefined}, () => {
            if (action) {
                if (action === EnumEmittedPrescriptionAction.NEW) {
                    if (this.props.addTab) {
                        this.props.addTab(this.props.t('prescriptionsList:prescriptionDetailTabTitle', {
                                nombrePaciente: this.state.patientSelected?.namePersona,
                                idPrescripcionGrupo,
                            }),
                            <NewPrescriptionsContainer key={idPrescripcionGrupo}
                                                       idPrescripcionGrupo={idPrescripcionGrupo}
                                                       addTab={this.props.addTab} closeTab={this.props.closeTab}
                                                       pending hideOptions={this.props.hideOptions}
                            />,
                            MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer));
                    }
                }
                if (action === EnumEmittedPrescriptionAction.MODIFY) {
                    this.loadData();
                    if (this.props.addTab) {
                        this.props.addTab(this.props.t('prescriptionsList:prescriptionDetailTabTitle', {
                                nombrePaciente: this.state.patientSelected?.namePersona,
                                idPrescripcionGrupo,
                            }),
                            <NewPrescriptionsContainer key={idPrescripcionGrupo}
                                                       idPrescripcionGrupo={idPrescripcionGrupo}
                                                       addTab={this.props.addTab} closeTab={this.props.closeTab}
                                                       pending hideOptions={this.props.hideOptions}
                            />,
                            MenuConfig.getConfigById(COMPONENT_IDS.newPrescriptionContainer));
                    }
                }
                if (action === EnumEmittedPrescriptionAction.VOID) {
                    this.loadData();
                }
            }
        });
    };

    private onClickPrescripcionesAnteriores = () => {

        this.checkPatientConsentimiento(this.state.patientSelected!.idPaciente!, () => {
            this.setState({
                prestacionesAnterioresVisible: true,
                prestacionesAnterioresKey: new Date().getTime(),
            });
        });
    };

    private onClosePrescripcionesAnteriores = () => {
        this.setState({prestacionesAnterioresVisible: false});
    };

    private consultReplace = (idPrescripcion: number, idTransaccion: number) => {
        Rest<{ type: string, idPrescripcion: number, idTransaccion: number }, any>()
            .operation({
                type: 'CodeNacionalReceiptReplace',
                idPrescripcion,
                idTransaccion,
            }).then(response => {
            if (response) {
                notification['success']({
                    message: this.props.t('prescriptionReceipt:receiptCnReplaceSuccess'),
                });
                this.loadData();
            } else {
                notification['error']({
                    message: this.props.t('prescriptionReceipt:receiptCnReplaceError'),
                });
            }
        });
    };

    private onClickAditionalPatientInfo = () => {
        this.setState({
            aditionalPatientInfoContainer: true,
            aditionalPatientInfoKey: new Date().getTime(),
        });
    };

    private onCloseAditionalPatientInfo = (save?: boolean) => {
        if(save){
            
            this.setState({alertsRefresh: this.state.alertsRefresh+1});
            
            this.showSelectedPatientDataDetails(this.state.patientSelected?.idPaciente);
        }
        this.setState({aditionalPatientInfoContainer: false});
    };

    private onClickPrescriptionFavList = () => {
        this.setState({prescriptionFavListVisible:true,prescriptionFavListKey: new Date().getTime()})
    }

    private onClosePrescriptionFavList = (idPrescripcionGrupo?: number) => {
        this.setState({prescriptionFavListVisible:false,  idPrescriptionGroup: (idPrescripcionGrupo!=null && idPrescripcionGrupo!=-1) ? idPrescripcionGrupo : this.state.idPrescriptionGroup}, () => { if(idPrescripcionGrupo!=-1){
                this.loadPrescriptionData();
            }
        })
        
        
    }

    private onSelectFav = (idPrescripcionFav: number) => {
        Rest<{ type: string, idPrescriptionFav: number, idPrescriptionGroup: number, idPaciente: number, idLocation: number, idDiagnostico: number}, any>()
            .operation({
                type: 'AddFavPrescriptionToPrescriptionGroup',
                idPrescriptionFav: idPrescripcionFav,
                idPrescriptionGroup: this.state.idPrescriptionGroup!,
                idPaciente: this.state.patientSelected!.idPaciente!,
                idLocation: this.state.idSelectedLocation!,
                idDiagnostico: this.state.diagnosticoSelectedId!
            }).then((response) => {
                if(response==-1){
                    notification['warning']({
                        message: this.props.t('alreadyPsychotropicNarcoticError')
                    });  
                }else{
                    this.onClosePrescriptionFavList(response);
                }
        });

    }
    private setPrescriptionesAnteriores = (prescriptions: PrescripcionAnterior[]) => {
        return _(prescriptions)
            .groupBy('idPrescripcion')
            .map((value) => ({
                idPrescripcion: value[0].idPrescripcion,
                typeProducto: value[0].typeProducto,
                typeEstado: value[0].typeEstado,
                namePrestador: value[0].namePrestador,
                typePosology: value[0].typePosologia,
                typeIdentificacion: value[0].typeIdentificacion,
                codeIdentificacion: value[0].codeIdentificacion,
                codeDcpf: value[0].codeDcpf,
                nameDcpf: value[0].nameDcpf,
                valueComposicionFmv: value[0].valueComposicionFmv,
                valuePresentacion: value[0].valuePresentacion,
                codeCodigoNacional: value[0].codeCodigoNacional,
                codeCodigoNacionalPres: value[0].codeCodigoNacionalPres,
                valueDosis: value[0].valueDosis,
                valueUnidadContenido: value[0].valueUnidadContenido,
                typeFrecuencia: value[0].typeFrecuencia,
                valueFrecuencia: value[0].valueFrecuencia,
                typeDuracion: value[0].typeDuracion,
                valueDuracion: value[0].valueDuracion,
                dateInicioTratamiento: value[0].dateInicioTratamiento,
                descIndicacionesPaciente: value[0].descIndicacionesPaciente,
                descIndicacionesFarmaceutico: value[0].descIndicacionesFarmaceutico,
                codeConfidencial: value[0].codeConfidencial,
                flagVisado: value[0].flagVisado,
                dateIniVisado: value[0].dateIniVisado,
                dateFinVisado: value[0].dateFinVisado,
                pctRegAportacion: value[0].pctRegAportacion,
                dateEmision: value[0].dateEmision,
                typePosologiaEspecial: value[0].typePosologiaEspecial,
                textoPosology: value[0].textoPosology,
                photos: value[0].idVademecumImagen && _(value)
                    .groupBy('idVademecumImagen')
                    .map((value) => ({
                        idVademecumImagen: value[0].idVademecumImagen,
                        valueImagen: value[0].valueImagen,
                    })).value()
            }))
            .orderBy('dateEmision', 'desc')
            .value() as PrescripcionAnterior[];
    }


    private getPrescripcionesActivas = () => {
        Rest<{ type: string; idPaciente: number; typeEstado: number; namePrestadorOrMedicamento?: string }, PrescripcionAnterior[]>()
            .operation({
                    type: 'SelectPrescripcionesAnterioresActivas',
                    idPaciente: this.state.patientSelected?.idPaciente!,
                    typeEstado: 1,
                }, true
            ).then(response => {
                response.forEach(p => {
                    p.namePrestador = concatName(p.nameNombrePrestador!, p.nameApellido1Prestador!, p.nameApellido2Prestador);
                });
                    this.setState({
                        prescripcionesActivas: this.setPrescriptionesAnteriores(response),
                        prescripcionesActivasVisible:true,
                });
            });
    }

    private onClickReceitButton = () => {

        if (!this.state.patientSelected || !this.state.selectedLocation) {
            notification['warning']({
                message: this.props.t('receiptMenuError')
            });
            return;
        }
        if(this.state.hasPsychotropicNarcotic && this.state.prescriptions && this.state.prescriptions.length!=0){
            notification['warning']({
                message: this.props.t('alreadyPsychotropicNarcoticError')
            });
            return;
        }
        this.onClickReceiptConfiguration();
        
    }

    private controlPsychotropicNarcotic = () => {
        if(this.state.idPrescriptionGroup && this.state.prescriptions && this.state.prescriptions.length!=0){ 
            Rest<{ type: string; idPrescriptionGroup?: number}, boolean>()
                .operation({
                        type: 'PrescriptionGroupHasPsychotropicNarcotic',
                        idPrescriptionGroup: this.state.idPrescriptionGroup
                    }, true
                ).then(response => {
                    this.setState({hasPsychotropicNarcotic: response}, () => this.onClickReceitButton());
                    }
                );
        }else{
            this.onClickReceitButton();
        }
    }

    private onClickButtonAddFavorite = (pres: PrescripcionPosologyInfo) => {
            Rest<{ type: string, 
                typePrescription: number, 
                idVademecum?: number, 
                codeDcpf?: string,
                productSpecification?: string,
                duration: number, 
                dosage: number, 
                typePosology?: number,
                frequency: number, 
                frequencyType: number, 
                packageDuration: number, 
                nPackages: number,
                patientIndications: string, 
                pharmacyIndications?: string, 
                packageId?: number,
                valuePresentacion: string,
                codeCodigoNacional?: string,
                idAccesoryEffect?: string,
                nameAccesoryEffect?: string,
                typePosologiaEspecial?: number,
                idUnidad?: number
            }, any>().operation({
            type: 'SavePrescriptionFav',
            typePrescription: pres.typeProducto,
            idVademecum: pres.idVademecum,
            codeDcpf: pres.codeDcpf,
            productSpecification: pres.valueComposicionFmv,
            duration: pres.valueDuracion,
            dosage: pres.valueDosis,
            typePosology: pres.typePosologia,
            frequency: pres.valueFrecuencia,
            frequencyType: pres.typeFrecuencia,
            packageDuration: pres.valueDuracionEnvaseDias!,
            nPackages: pres.nbrEnvasesTotal!,
            patientIndications: pres.descIndicacionesPaciente,
            pharmacyIndications: pres.descIndicacionesFarmaceutico,
            packageId: pres.packageId,
            valuePresentacion: pres.valuePresentacion,
            codeCodigoNacional: pres.codeCodigoNacional,
            idAccesoryEffect: pres.codeCodigoNacionalPres,
            nameAccesoryEffect: pres.valuePresentacion,
            typePosologiaEspecial: pres.typePosologiaEspecial,
            idUnidad: pres.idUnidad
        }).then(() => {
            notification['success']({ 
                message: this.props.t('addedToFavorite'),
                duration: 2
            });
        });
    }

    private maybeNotifyParentWindow = () => {
        let inIframe = false;
        try {
            inIframe = window.self !== window.parent;
        } catch (e) {
            inIframe = true;
        }

        if (window.parent && inIframe) {
            window.parent.postMessage('ereceta-prescripcion-emitida', '*');
        }
    }

}

export default withTranslation('prescription')(withRouter(NewprescriptionsContainer));
