import React, { ReactNode } from 'react';
import {withTranslation, WithTranslation} from 'react-i18next';
import {RouteComponentProps, withRouter} from 'react-router-dom';
import SelectPatientContainer from './selectPatientContainer';
import NewPatientContainer from './newPatientContainer';
import PersonaSelected from './entities/personaSelected';
import {Store} from 'rc-field-form/lib/interface';
import TutorData from './entities/tutorData';
import PatientData from './entities/patientData';
import TutorFormContainer from './tutorFormContainer';
import {concatName, Rest} from '../../utils/utils';
import SelectTutorContainer from './selectTutorContainer';
import TutorSelected from './entities/tutorSelected';
import PatientDataDetails from './entities/patientDataDetails';
import WaitingConsentContainer from '../../consent/WaitingConsentContainer';
import ConfirmConsentInPerson from './confirmConsentInPerson';

import _ from 'lodash';
import NewPrescriptionsContainer from '../newPrescriptionsContainer';
import { TabExtendedProps } from '../../MainLayout';

interface IProps {
    addTab?: (tabCaption: string, component: ReactNode, extendedProps?: TabExtendedProps) => void;
    patientKey?: number;
    visible?: boolean;
    idPatient?: number;
    onClose: () => void;
    addPatientSelected: (idPatient: number, consent?: boolean) => void;
    editPatient?: boolean;
    patientSelected?: PatientData
    editPatientSelectedWithoutMail?: (selectedPatient: PersonaSelected) => void;
    fromListaPacientes?: boolean;
    closeTab?: () => void;
}

interface WaitingConsentPersonData {
    idPersona: number;
    personaName: string;
    personaMail: string;
    token: string;
    typeConsentimiento?: number;
}

export interface IState {

    editPatientWithoutMail?: boolean; // se utilizan unicamente para pacientes que viene sin mail (paciente de GCO), que debemos de editar para introducir su mail.
    idPacienteWithoutConsentiment?:number // se utiliza unicamenete para los paciente que deben firma el consentimiento.
    idTutorWithoutConsentiment?: number;
    namePatientSingConsentiment?: string; // contiene el nombre del paciente que debe firma el consentimiento (Mayor de edad o tutor)

    loaded?: boolean;

    patient?: PatientData;
    selectedPatient?: PersonaSelected;

    selectPatientKey?: number;
    selectPatientVisible?: boolean;

    newPatientKey?: number;
    newPatientVisible?: boolean;
    fromExistingPerson?: boolean;

    waitingPrestadorKey?: number;
    waitingPrestadorVisible?: boolean;
    waitingData?: WaitingConsentPersonData;

    tutor?: TutorData;
    tutorConsentimiento?: boolean;
    isTutorConsentimiento?: boolean;

    tutorFormKey?: number;
    tutorFormVisible?: boolean;

    indexTutor?: number;

    selectTutorKey?: number;
    tutorSelectVisible?: boolean;
    tutorFromExistingPerson?: boolean;

    newTutorFromPerson?: TutorData;

    noResult?: boolean;

    confirmationConsentInPersonVisible?: boolean;      

    hasEmailPacienteConsentiment?: boolean;
}

class PatientCommonContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public state: IState = {}

    public componentDidMount() {

        if (!this.props.visible) {
            return;
        }
        if(this.props.fromListaPacientes){
            this.newPatient();
        }

        if (this.props.idPatient && this.props.editPatient){

            Rest<{ type: string, idPaciente: number }, PatientDataDetails>()
                .operation({
                    type: 'GetPatientDataDetails',
                    idPaciente: this.props.idPatient
                }).then(response => {
                    response.patientData.namePersona = concatName(response.patientData.nameNombre, response.patientData.nameApellido1, response.patientData.nameApellido2);
                    this.setState({
                        loaded: true,
                        patient: response.patientData,
                        tutor: response.currentTutor,
                        selectPatientVisible: !this.props.editPatient,
                        newPatientVisible: this.props.editPatient,
                        selectedPatient: {
                            idPersona: response.patientData.idPaciente,
                            idPaciente: response.patientData.idPaciente,
                            namePersona: response.patientData.namePersona,
                            codeIdentificacion: response.patientData!.codeIdentificacion
                        } as PersonaSelected
                    }, () => {
                        if (this.state.tutor) {
                            this.checkInitialTutorConsentimientoState();
                        }
                    });
            });
        } else {
            if(!this.props.fromListaPacientes){
                this.setState({loaded: true, selectPatientVisible: true, //en lista pacientes despues de añadir un paciente aparece el modal de buscar paciente, sin funcionalidad mas alla de intruducir dni
                    selectedPatient: this.props.patientSelected && {
                        idPersona: this.props.patientSelected.idPaciente,
                        idPaciente: this.props.patientSelected.idPaciente,
                        namePersona: this.props.patientSelected.namePersona,
                        typeIdentificacion: this.props.patientSelected.typeIdentificacion,
                        codeIdentificacion: this.props.patientSelected.codeIdentificacion
                    } as PersonaSelected});
            }else{
                this.setState({loaded: true, selectPatientVisible: false, //en lista pacientes despues de añadir un paciente aparece el modal de buscar paciente, sin funcionalidad mas alla de intruducir dni
                    selectedPatient: this.props.patientSelected && {
                        idPersona: this.props.patientSelected.idPaciente,
                        idPaciente: this.props.patientSelected.idPaciente,
                        namePersona: this.props.patientSelected.namePersona,
                        typeIdentificacion: this.props.patientSelected.typeIdentificacion,
                        codeIdentificacion: this.props.patientSelected.codeIdentificacion,
                    } as PersonaSelected});
                /*if(this.props.addTab) { //Para en un futuro despues de añadir el paciente nuevo, prescribirle
                    this.props.addTab(this.props.t('menu:newPrescription'), <NewPrescriptionsContainer idPaciente={this.props.patientSelected?.idPaciente} addTab={this.props.addTab} closeTab={this.props.closeTab} pending={true} />);
                }*/
            }
            
        }
    }

    public render() {
        return (
            this.state.loaded ?
                <>
                    <SelectPatientContainer
                        key={this.state.selectPatientKey}
                        visible={this.state.selectPatientVisible}
                        onClose={this.onCloseSelectPatient}
                        newPatient={this.newPatient}
                        checkConsentimiento={this.checkPatientConsentimiento}
                        selectedPatient={this.state.selectedPatient}
                        addPatientSelected={this.addPatientSelectedSilent}
                        editPatientSelectedWithoutMail={this.editPatientSelectedWithoutMail}
                    />
                    <NewPatientContainer
                        key={this.state.newPatientKey}
                        visible={this.state.newPatientVisible}
                        onClose={this.onCloseNewPatient}
                        addTutor={this.addTutor}
                        saveNewPatient={this.savePatient}
                        currentTutor={this.state.tutor}
                        editTutor={this.editTutor}
                        deleteTutor={this.deleteTutor}
                        patient={this.state.patient}
                        editPatient={this.props.editPatient || this.state.editPatientWithoutMail}
                        fromExistingPerson={this.state.fromExistingPerson}
                        tutorConsentimiento={this.state.tutorConsentimiento}
                        getTutorConsentimiento={this.checkTutorConsentimiento}
                    />

                    <SelectTutorContainer
                        tutorSelectVisible={this.state.tutorSelectVisible}
                        setNoResultValue={this.setNoResultValue}
                        noResult={this.state.noResult}
                        key={this.state.selectTutorKey}
                        onCloseSelectTutor={this.onCloseSelectTutor}
                        tutorSelected={this.tutorSelected}
                        newTutor={this.newTutor}
                    />
                    <TutorFormContainer
                        key={this.state.tutorFormKey}
                        visible={this.state.tutorFormVisible}
                        onClose={this.onCloseSetTutorData}
                        setTutor={this.saveTutor}
                        currentTutor={this.state.tutor}
                        tutorFromExistingPerson={this.state.tutorFromExistingPerson}
                        newTutorFromPerson={this.state.newTutorFromPerson}
                    />                

                    <WaitingConsentContainer
                        key={this.state.waitingPrestadorKey}
                        visible={this.state.waitingPrestadorVisible}
                        idPersona={this.state.waitingData?.idPersona!}
                        patientName={this.state.waitingData?.personaName!}
                        patientMail={this.state.waitingData?.personaMail!}
                        token={this.state.waitingData?.token}
                        onClose={this.onCloseWaitingModal}
                        checkConsentimiento={this.checkPatientConsentimientoMail}/>

                    <ConfirmConsentInPerson
                        visible={this.state.confirmationConsentInPersonVisible}
                        confirmConsentimientoPapel={this.confirmConsentimientoPapel}
                        confirmConsentimientoEmail={this.confirmConsentimientoEmail}
                        cancelConsentimiento={this.cancelConsentimiento}
                        patientName={this.state.namePatientSingConsentiment!}
                        currentTutor={this.state.selectedPatient?.currentTutor!}
                        hasEmailPacienteConsentiment={this.state.hasEmailPacienteConsentiment}
                        />
                </>
                : null);
    }


    private showSelectConsentimientoPaciente = (idPatient : number) => {
        Rest<{ type: string, idPaciente: number }, {hasEmail: boolean,nombrePaciente:string}>()
        .operation({
            type: 'ComprobarPatientHasEmail',
            idPaciente: idPatient
        }).then(response => {

            this.setState({
                idPacienteWithoutConsentiment: idPatient,
                hasEmailPacienteConsentiment: response.hasEmail,
                namePatientSingConsentiment: response.nombrePaciente,
                confirmationConsentInPersonVisible: true, 
                selectPatientVisible: false,
                newPatientVisible: false,
            }); 
        });
    }    

    private checkTutorConsentimiento = (idTutor?: number) => {
        this.setState({
            idTutorWithoutConsentiment: idTutor,
            hasEmailPacienteConsentiment: (this.state.tutor?.valueEmail)? true: false,
            namePatientSingConsentiment: concatName(this.state.tutor!.nameNombre, this.state.tutor!.nameApellido1, this.state.tutor!.nameApellido2),
            confirmationConsentInPersonVisible: true, 
            selectPatientVisible: false,
            newPatientVisible: false,
            isTutorConsentimiento: true
        }); 
    }
    
    private cancelConsentimiento = () => {
        this.setState({
            confirmationConsentInPersonVisible: false, 
            newPatientVisible: false
        });  
    }

    private confirmConsentimientoPapel = () => {
        var idPaciente: number;
        if (this.state?.idPacienteWithoutConsentiment) {
            idPaciente = this.state?.idPacienteWithoutConsentiment;
        }else if(this.state?.idTutorWithoutConsentiment){
            idPaciente = this.state?.idTutorWithoutConsentiment
        }else {
            idPaciente = this.state?.selectedPatient?.idPaciente!;
        }
        this.onCloseNewPatient();
        this.checkPatientConsentimientoPapel (idPaciente,this.state?.idTutorWithoutConsentiment ? true : false);
    }

    private confirmConsentimientoEmail = () => {
        var idPaciente: number;
        if (this.state?.idPacienteWithoutConsentiment) {
            idPaciente = this.state?.idPacienteWithoutConsentiment;
        } else if(this.state?.idTutorWithoutConsentiment){
            idPaciente = this.state?.idTutorWithoutConsentiment
        } else {
            idPaciente = this.state?.selectedPatient?.idPaciente!;
        }
        this.onCloseNewPatient();
        this.checkPatientConsentimientoMail(idPaciente, this.state?.idTutorWithoutConsentiment ? true : false);        
    }  
    
    private onCloseSelectTutor = () => {
        this.setState({tutorSelectVisible: false, newPatientVisible: true, selectTutorKey: new Date().getTime()});
    }

    private addTutor = () => {
        this.setState({
            tutorSelectVisible: true,
            newTutorFromPerson: undefined,
            tutorFromExistingPerson: false,
            newPatientVisible: false,
            noResult: false,
        });
    }

    private onCloseSetTutorData = () => {
        this.setState({tutorFormVisible: false, tutorSelectVisible: false});

        // An existing tutor
        if (typeof this.state.tutor?.idTutor !== 'undefined') {
            this.setState({newPatientVisible: true});
        } else { //A new one
            this.setState({newPatientVisible: true});
        }
    }

    private tutorSelected = (tutor: TutorData) => {
        this.setState({tutorSelectVisible: false, tutor: tutor, newPatientVisible: true}, () => {
            this.checkInitialTutorConsentimientoState();
        });
    }

    private saveTutor = (tutor: TutorData) => {
        this.setState({
            tutorFormVisible: false,
            tutorSelectVisible: false,
            newTutorFromPerson: undefined,
            tutorFromExistingPerson: false,
            tutor: tutor,
            newPatientVisible: true,
            tutorConsentimiento: true
        });
    }

    private editTutor = () => {
        this.setState({
            newPatientVisible: false,
            tutorFormVisible: true, tutorFormKey: new Date().getTime()
        });
    }

    private deleteTutor = () => {
        this.setState({tutor: undefined});
    }

    private newPatient = (newPatientData?: PatientData) => {
        this.setState({
            fromExistingPerson: newPatientData && newPatientData.idPersona !== undefined,
            selectPatientVisible: false,
            newPatientVisible: true,
            newPatientKey: new Date().getTime(),
            patient: newPatientData,
            tutor: undefined
        });
    }

    /**
     * Carga los datos del paciente para edicion en caso que no tenga email (como puede ocurrir con los pacientes qeu vienen de GCO)
     * @param idPatient 
     */
    private editPatientSelectedWithoutMail = (idPatient: number) => {
        Rest<{ type: string, idPaciente: number }, PatientDataDetails>()
        .operation({
            type: 'GetPatientDataDetails',
            idPaciente: idPatient
        }).then(response => {
            response.patientData.namePersona = concatName(response.patientData.nameNombre, response.patientData.nameApellido1, response.patientData.nameApellido2);
            this.setState({
                loaded: true,
                patient: response.patientData,
                tutor: response.currentTutor,
                selectPatientVisible: false,
                newPatientVisible: true,
                editPatientWithoutMail: true,
                selectedPatient: {
                    idPersona: response.patientData.idPaciente,
                    idPaciente: response.patientData.idPaciente,
                    namePersona: response.patientData.namePersona,
                    codeIdentificacion: response.patientData!.codeIdentificacion
                } as PersonaSelected
            }, () => {
                if (this.state.tutor) {
                    this.checkInitialTutorConsentimientoState();
                }
            });
        });
    }    

    private newTutor = (tutor?: TutorSelected) => {
        this.setState({
            tutorFromExistingPerson: !!tutor,
            newTutorFromPerson: tutor,
            tutorFormVisible: true,
            tutorFormKey: new Date().getTime(),
            tutorSelectVisible: false,
            newPatientVisible: false,
            
        });
    }

    private onCloseSelectPatient = () => {
        this.setState({selectPatientVisible: false}, () => _.debounce(() => this.props.onClose(), 250));
    }

    private savePatient = (values: Store) => {
        //Tutor's states:
        // idTutor==null, idPersona==null -> new tutor
        // idTutor==null, idPersona!=null -> new tutor from existing person
        // idTutor!=null, idPersona!=null -> existing tutor
        //const usePatientInProps = this.props.editPatient || this.state.fromExistingPerson;
        //const patientData = usePatientInProps ? {...this.state.patient!} : {} as PatientData;
        const patientData = {...this.state.patient} as PatientData;
        //patientData.idPaciente = this.props.idPatient;
        patientData.nameNombre = values.name;
        patientData.nameApellido1 = values.surname1;
        patientData.nameApellido2 = values.surname2;
        patientData.dateNacimiento = values.dateBirt;
        patientData.typeSexo = values.gender;
        patientData.typeIdentificacion = values.typeDoc;
        patientData.codeIdentificacion = values.codeIdentification;
        patientData.codeTarjetaSanitaria = values.cardNumber;
        patientData.idMutualidad = values.idMutualidad;
        patientData.idAseguradora = values.idAseguradora;
        patientData.valueTelefono = values.phone;
        patientData.valueEmail = values.email;
        patientData.idIdioma = values.ididioma;

        if (this.state.fromExistingPerson) { //New patient from the exiting person
            Rest<{ type: string, patientData: PatientData, currentTutor?: TutorData }, any>()
                .operation({
                    type: 'CreatePatientForPrescriptionFromPersona',
                    patientData: patientData,
                    currentTutor: this.state.tutor
                }).then(response => {
                this.onCloseNewPatient();
                this.checkPatientConsentimiento(response.personaSelected.idPersona);
                this.setState({
                    loaded: true, 
                    selectPatientVisible: false,
                    newPatientVisible: false,
                    editPatientWithoutMail: false,
                    selectedPatient: {
                        idPersona: response.personaSelected.idPersona,
                        namePersona: response.personaSelected.namePersona,
                    } as PersonaSelected});   
            });
        } else if (this.props.editPatient || this.state.editPatientWithoutMail) { //Edit data for existing patient + person
            Rest<{ type: string, patientData: PatientData, currentTutor?: TutorData, fromExistingPerson?: boolean }, PersonaSelected>()
                .operation({
                    type: 'EditPatientForPrescription',
                    patientData: patientData,
                    currentTutor: this.state.tutor,
                }).then(response => {
                    this.onCloseNewPatient();
                    this.checkPatientConsentimiento(response.idPersona);
                    this.setState({
                        loaded: true, 
                        selectPatientVisible: false,
                        newPatientVisible: false,
                        editPatientWithoutMail: false,
                        selectedPatient: {
                            idPersona: response.idPersona,
                            namePersona: response.namePersona,
                        } as PersonaSelected});   
            });
        } else { //New Persona + Patient
            Rest<{ type: string, patientData: PatientData, currentTutor?: TutorData }, any>()
                .operation({
                    type: 'NewPatientForPrescription',
                    patientData: patientData,
                    currentTutor: this.state.tutor
                }).then(response => {
                    /*this.setState({
                        selectedPatient: {...response.personaSelected, idPaciente: response.personaSelected.idPersona},
                        newPatientVisible: false,
                        selectPatientVisible: true,
                        selectPatientKey: new Date().getTime()
                    });*/

                    this.setState({
                        loaded: true, 
                        selectPatientVisible: false,
                        newPatientVisible: false,
                        confirmationConsentInPersonVisible: true, 
                        hasEmailPacienteConsentiment: (patientData.valueEmail != null && !this.state.tutor || this.state.tutor && this.state.tutor.valueEmail != null) ? true : false ,
                        idPacienteWithoutConsentiment: response.patientData.idPaciente,
                        namePatientSingConsentiment: concatName(response.patientData.nameNombre, response.patientData.nameApellido1, response.patientData.nameApellido2),
                        selectedPatient: {
                            idPersona: response.personaSelected.idPersona,
                            namePersona: response.personaSelected.namePersona,
                            currentTutor: response.currentTutor?.idPersona!
                        } as PersonaSelected});  
            });
        }
    }

    private checkPatientConsentimientoMail = (idPatient: number, isTutor?: boolean) => {
        Rest<{ type: string, idPersona: number}, any>().operation({
            type: 'CheckConsentimientoPersona',
            idPersona: idPatient,
        }).then(response => {
            if (response.token) {
                this.openWaitingModal({
                    idPersona: idPatient,
                    personaName: response.personaName,
                    personaMail: response.personaMail,
                    token: response.token,
                    typeConsentimiento: 2,
                });
            } else if (isTutor){
                this.editTutor();
            } else {
                this.setState({selectPatientVisible: false}, () => this.props.addPatientSelected(idPatient, !response.implicit));
            }
        });
    }

    private checkPatientConsentimientoPapel = (idPatient: number, isTutor?: boolean) => {
        Rest<{ type: string, idPersona: number}, any>().operation({
            type: 'CheckConsentimientoPersonaPapel',
            idPersona: idPatient,
        }).then(response => {
            if (response.idConsentimiento) {
                Rest<{type: string, idConsentimiento: number}, string>().operation({
                    type: 'AceptConsentimiento',
                    idConsentimiento: response.idConsentimiento
                }).then(response => {
                    if (response) {
                        this.onCloseWaitingModal (idPatient, true);            
                    }
                });
            } else if (isTutor){
                this.editTutor();
            } else {
                this.setState({selectPatientVisible: false}, () => this.props.addPatientSelected(idPatient, !response.implicit));
            }
        });
    }  

    /**
     * Indica si el paciente tiene un consentimiento aceptado.
     * 
     * @param idPatient 
     */
    private checkPatientConsentimiento = (idPatient: number) => {
        Rest<{ type: string, idPersona: number}, any>().operation({
            type: 'SimpleCheckConsentimientoPersona',
            idPersona: idPatient,
        }).then(response => {
            if (response) {
                this.setState({
                    selectPatientVisible: false
                });                
                this.onCloseWaitingModal(idPatient, true);  
            } else {
                this.showSelectConsentimientoPaciente(idPatient);
            }
        });
    }
    

    private addPatientSelectedSilent = (idPatient: number) => {
        this.setState({selectPatientVisible: false}, () => this.props.addPatientSelected(idPatient, false));
    }

    private openWaitingModal = (waitingData: WaitingConsentPersonData) => {
        this.setState({
            waitingData: waitingData,
            selectPatientVisible: false,
            waitingPrestadorVisible: true,
            waitingPrestadorKey: new Date().getTime()
        });
    }

    private onCloseWaitingModal = (idPersona: number, acepted?: boolean) => {
        this.setState({waitingPrestadorVisible: false, confirmationConsentInPersonVisible: false}, () => {
            if (acepted) {
                if (this.state.isTutorConsentimiento) {
                    this.setState({
                        tutorConsentimiento: true,
                        isTutorConsentimiento: false,
                        newPatientVisible: false
                    }, () =>  this.editTutor());
                } else {
                    this.props.addPatientSelected(idPersona, false);
                }
            } else {
                if (this.state.isTutorConsentimiento) {
                    this.setState({
                        tutorConsentimiento: false,
                        isTutorConsentimiento: false,
                    })
                } else {
                    this.setState({
                        tutorConsentimiento: false,
                        isTutorConsentimiento: false,
                        selectPatientVisible: false
                    })
                }
            }
        });
    }

    private checkInitialTutorConsentimientoState = () => {
        Rest<{ type: string, idPersona: number }, boolean>().operation({
            type: 'SimpleCheckConsentimientoPersona',
            idPersona: this.state.tutor!.idPersona!,
        }).then(response => {
            this.setState({tutorConsentimiento: response});
        });
    }

    private onCloseNewPatient = () => {
        //if (this.props.editPatient) {
            this.setState({
                newPatientVisible: false,
                confirmationConsentInPersonVisible: false

            });
            this.props.onClose();
        //} else {
        //    this.setState({newPatientVisible: false, selectPatientVisible: true});
        //}
    }

    private setNoResultValue = (value: boolean) => {
        this.setState({noResult: value});
        
    }
}

export default withTranslation('newPatientPrescription')(withRouter(PatientCommonContainer));
