import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Functions, Rest } from '../../utils/utils';

import _ from 'lodash';
import { notification } from 'antd';

import PrestadorConfigContext from '../../context/PrestadorConfigContext';
import PosologyConfigContainer from '../savePrescription/medicine/PosologyConfigContainer';
import Medicine from '../savePrescription/entities/medicine';
import Posology from '../savePrescription/entities/posology';
import Dcpf from '../savePrescription/entities/dcpf';
import PrescriptionConfigContainer from '../savePrescription/medicine/PrescriptionConfigContainer';
import SelectMedicineContainer from '../savePrescription/medicine/SelectMedicineContainer';
import EnumPrescriptionType from '../../enum/EnumPrescriptionType';

interface IProps {
    visible?: boolean;
    typePrescription?: number;
    idPrescriptionMod?: number;
    onClose: (idPrescription?: number) => void;
    setMedicine: (medicine: any) => Medicine;
}

export interface IState {
    prescriptionConfigVisible?: boolean;
    prescriptionConfigKey?: number;
    medicineSelected?: Medicine;
    otherProductSelected?: any;
    dcpfSelected?: Dcpf;
    selectMedicineVisible?: boolean;
    selectMedicineKey?: number;
    posologySelected?: Posology;
    posologyVisible?: boolean;
    posologyKey?: number;
    productSpecification?: string;
    typePrescription?: number;
}

class NewEditFavPrescriptionContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    state: IState = {
        prescriptionConfigVisible: false
    };

    public componentDidMount() {
        if (this.props.visible) {
            if (this.props.idPrescriptionMod) {
                this.loadData();
            } else {
                this.setState({typePrescription: this.props.typePrescription,prescriptionConfigVisible: true, prescriptionConfigKey: new Date().getTime()});
            }
        }
    }
    static contextType = PrestadorConfigContext;

    public render() {
        return (
            <>
                <PrescriptionConfigContainer
                    key={this.state.prescriptionConfigKey}
                    visible={this.state.prescriptionConfigVisible}
                    typePrescription={this.state.typePrescription}
                    medicineSelected={this.state.medicineSelected}
                    dcpfSelected={this.state.dcpfSelected}
                    posologySelected={this.state.posologySelected}
                    productSpecification={this.state.productSpecification}
                    idPrescription={this.props.idPrescriptionMod}
                    onClose={this.onClosePrescriptionConfig}
                    onClickSelectMedicine={this.onClickSelectMedicine}
                    onClickPosologyConfig={this.onClickPosologyConfig}
                    saveSpecification={this.saveSpecification}
                    setMedicine={this.props.setMedicine} 
                    otherProductSelected={this.state.otherProductSelected}
                    
                   />

                <SelectMedicineContainer
                    key={this.state.selectMedicineKey}
                    visible={this.state.selectMedicineVisible}
                    typePrescription={this.state.typePrescription}
                    onClose={this.onCloseSelectMedicine}
                    setMedicine={this.props.setMedicine} />

                <PosologyConfigContainer
                    key={this.state.posologyKey}
                    visible={this.state.posologyVisible}
                    medicine={this.state.medicineSelected}
                    dcpf={this.state.dcpfSelected}
                    setMedicine={this.props.setMedicine} 
                    posologySelected={this.state.posologySelected}
                    productSpecification={this.state.productSpecification}
                    otherProduct={this.state.otherProductSelected}
                    onClose={this.onClosePosology}
                />

            </>

        )
    }

    private loadData = () => {
        Rest<{ type: string, idPrescripcionFav: number }, any>()
        .operation({
            type: 'GetEditPrescriptionFavDetails',
            idPrescripcionFav: this.props.idPrescriptionMod!,
        }).then(response => {
            this.setData(response);
        });
    }

    private setData = (data: any) => {
        let productSpecification;
        let medicineSelected;
        let dcpfSelected;
        let otherProductSelected;
        let typePrescription = this.props.typePrescription

        if ((data.prescripcion[0].codeCodigoNacional || data.prescripcion[0].codeDcpf) && this.props.typePrescription === EnumPrescriptionType.VACUNA) {
            typePrescription = EnumPrescriptionType.VACUNA_CATALOGADA;
        }
        if ((data.prescripcion[0]?.codeCodigoNacional || data.prescripcion[0]?.codeDcpf) && this.props.typePrescription === EnumPrescriptionType.FORMULA_MAGISTRAL) {
            typePrescription = EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA;
        }
        if ((data.prescripcion[0]?.codeCodigoNacional || data.prescripcion[0]?.codeDcpf || data.prescripcion[0]?.packageId) && this.props.typePrescription === EnumPrescriptionType.NUTRICION) {
            typePrescription = EnumPrescriptionType.NUTRICION_CATALOGADA;
        }
        if ((data.prescripcion[0]?.codeCodigoNacional) && this.props.typePrescription === EnumPrescriptionType.PRODUCTO_SANITARIO) {
            typePrescription = EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO;
        }

        if (data.prescripcion[0].idVademecum) {
            const medicine = data.prescripcion[0];
            medicineSelected = {
                idVademecum: medicine.idVademecum,
                codeCodigoNacional: medicine.codeCodigoNacional,
                nameProducto: medicine.valuePresentacion,
                laboratory: medicine.valueLaboratorio,
                dosage: medicine.valueDosisMed,
                psychotropic: !!medicine.flagPsicotropo,
                narcotic: !!medicine.flagEstupefaciente,
                codePharmaceuticalForm: medicine.codeFormaFarmaceutica,
                namePharmaceuticalForm: medicine.valueFormaFarmaceutica,
                codeUnitContent: medicine.codeUnidadContenido,
                valueUnitContent: medicine.valueUnidadContenido,
                codeDcpf: medicine.codeDcpf,
                nameDcpf: medicine.nameDcpf,
                activePrinciples: _(data.prescripcion).groupBy('codePrincipioActivo').map((value) => ({ codeActivePrinciple: value[0].codePrincipioActivo, nameActivePrinciple: value[0].valuePrincipioActivo})).value(),
                vias: _(data.prescripcion).groupBy('codeViaAdministracion').map((value) => ({ codeVia: value[0].codeViaAdministracion, nameVia: value[0].valueViaAdministracion})).value(),
                docs: medicine.idVademecumDocumento && _(data.prescripcion).groupBy('idVademecumDocumento').map((value) => ({ idDocument: value[0].idVademecumDocumento, urlDocument: value[0].valueUrl})).value(),
                photos: medicine.idVademecumImagen && _(data.prescripcion).groupBy('idVademecumImagen').map((value) => ({ idImage: value[0].idVademecumImagen, image: value[0].valueImagen})).value()
            };
            
        } else if(data.dcpf) {
            dcpfSelected = data.dcpf;
        } 
        else if (data.prescripcion[0].packageId) {
            otherProductSelected = {
                packageId: data.prescripcion[0].packageId,
                nameProducto : data.prescripcion[0].valuePresentacion,
                valueUnidadContenido: data.prescripcion[0].valueUnidadContenido,
                descUnidadContenido: data.prescripcion[0].descUnidadContenido,
                codeCodigoNacional: data.prescripcion[0].codeCodigoNacional
            }
           
        }else {
            productSpecification = data.prescripcion[0].valueComposicionFmv;
            otherProductSelected = {
                codeCodigoNacional : data.prescripcion[0].codeCodigoNacional,
                nameProducto : data.prescripcion[0].valuePresentacion,
                valueUnidadContenido: data.prescripcion[0].valueUnidadContenido,
                descUnidadContenido: data.prescripcion[0].descUnidadContenido
            }
        }

        const posologySelected = {
            dateStart: data.prescripcion[0].dateInicioTratamiento,
            dateStartString: Functions.dateToString(data.prescripcion[0].dateInicioTratamiento),
            duration: data.prescripcion[0].valueDuracion,
            dosage: data.prescripcion[0].valueDosis,
            frequency: data.prescripcion[0].valueFrecuencia,
            typePosology: data.prescripcion[0].typePosologia,
            frequencyType: data.prescripcion[0].typeFrecuencia,
            packageDuration: data.prescripcion[0].valueDuracionEnvaseDias,
            nPackages: data.prescripcion[0].nbrEnvasesTotal,
            patientIndications: data.prescripcion[0].descIndicacionesPaciente,
            pharmacyIndications: data.prescripcion[0].descIndicacionesFarmaceutico,
            textoPosology: data.prescripcion[0].textoPosology,
            typePosologiaEspecial: data.prescripcion[0].typePosologiaEspecial,
            idUnidad: data.prescripcion[0].idUnidad
        };

        this.setState({medicineSelected, dcpfSelected, productSpecification, otherProductSelected, prescriptionConfigVisible: true, typePrescription:  typePrescription, prescriptionConfigKey: new Date().getTime(), posologySelected});
    }

    private onClosePrescriptionConfig = (idPrescription?: number) => {
        this.setState({ prescriptionConfigVisible: false}, () => this.props.onClose(idPrescription));
    }

    private onClickSelectMedicine = (change?: boolean) => {
        this.setState({ selectMedicineVisible: true, selectMedicineKey: change ? this.state.selectMedicineKey : new Date().getTime(), prescriptionConfigVisible: false });
    }

    private onClickPosologyConfig = () => {
        if (!this.state.medicineSelected && !this.state.productSpecification && !this.state.otherProductSelected && !this.state.dcpfSelected) {
            notification['info']({
                message: this.props.t('posologyError')
            });
            return;
        }
        this.setState({ posologyVisible: true, prescriptionConfigVisible: false, posologyKey: new Date().getTime() });
    }

    private onCloseSelectMedicine = (medicine?: Medicine, otherProduct?: any, dcpf?: Dcpf) => {
        this.setState({
            selectMedicineVisible: false,
            prescriptionConfigVisible: true,
            medicineSelected: dcpf ? undefined : (medicine ? medicine : this.state.medicineSelected),
            dcpfSelected: medicine ? undefined : (dcpf ? dcpf : this.state.dcpfSelected),
            otherProductSelected: otherProduct ? otherProduct : this.state.otherProductSelected});
       
    }

    private onClosePosology = (posology?: Posology) => {
        if(posology){
            Rest<{ type: string, idIdioma?: any,
                typePosology?: number, typeDuracion?: number, dateInicioPrescripcion?: Date, valueDuracion?: number, valueDosis?: number, typeFrecuencia?: number, valueFrecuencia?: number,
                typePosologia?: number, valueUnidadContenido?: number, typePosologiaEspecial?: number, idUnidad?: number}, any>().operation({
                type: 'GenerateTextoPosologiaFront',
                idIdioma: null,
                typeDuracion: 1,
                valueDuracion: posology.duration,
                valueDosis: posology.dosage,
                valueUnidadContenido: this.state.medicineSelected ? this.state.medicineSelected.valueUnitContent : (this.state.dcpfSelected ? this.state.dcpfSelected.valueUnidadContenido : this.state.otherProductSelected.valueUnidadContenido),
                typeFrecuencia: posology.frequencyType,
                valueFrecuencia: posology.frequency,
                dateInicioPrescripcion: posology.dateStart,
                typePosologia: posology.typePosology,
                typePosologiaEspecial: posology.typePosologiaEspecial,
                idUnidad: posology.idUnidad
            }).then(response => {
                
                this.setState({posologyVisible: false, prescriptionConfigVisible: true, posologySelected: {...posology,textoPosology: response}});
            });
        } else {
            this.setState({posologyVisible: false, prescriptionConfigVisible: true, posologySelected: this.state.posologySelected});
        }
    }

    private saveSpecification = (specification: string) => {
        this.setState({productSpecification: specification});
    }

}

export default withTranslation('medicineConfig')(withRouter(NewEditFavPrescriptionContainer));