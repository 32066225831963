import React, { ClipboardEvent } from 'react';

import { Store } from 'antd/lib/form/interface';
import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { Rest, UserInfo } from '../utils/utils';
import LoginOperation from './operations/loginOperation';
import LoginOperationResponse from './operations/loginOperationResponse';
import View from './Login';
import EnumCodeResultLogin from '../enum/EnumCodeResultLogin';
import {FormInstance, notification} from 'antd';
import CollegeErrorModal from './CollegeErrorModal';
import SignOtpModalContainer, { OtpCallerProps } from '../prescriptions/saveSing/signOtpModalContainer';


interface IProps {
    visible?: boolean;
    onClose?: () => void;
}

export interface IState extends OtpCallerProps {
    errorMessage?: string;
    warningMessage?: string;
    errorFields?: string[];
    codeResult?: number;
    showForm?: boolean;
    forgotPasswordVisible?: boolean;
    warningPasswordMessage?: string;
    errorPasswordMessage?: string;
    mailSent?: boolean;
    collegeErrorVisible?: boolean;
    collegeErrorKey?: number;
    otpCancelled?: boolean;
    privacyPolicyVisible?: boolean;
    comesFromContact?:boolean;
    linkRenovacion?: string;
}

class LoginModalContainer extends React.Component<WithTranslation & RouteComponentProps & IProps, IState> {

    public componentDidMount() {
        if (UserInfo.isPresent()) {
            this.props.history.push('/app');
        }
    }

    public state: IState = {

    };

    public render() {
        return (
            <>
            <View
                {...this.state}
                visible={this.props.visible && !this.state.signOtpVisible && !this.state.collegeErrorVisible}
                onClose={this.onCloseModal}
                onSubmit={this.login}
                onValidationFailed={this.validationFailed}
                tryAgain={this.tryAgain}
                onClickForgotPassword={this.onClickForgotPassword}
                onForgotPassword={this.onForgotPassword}
                validationPasswordFailed={this.validationPasswordFailed}
                closePasswordForm={this.closePasswordForm}
                onOtpCancel={this.onOtpCancel}
                onCancelarRegenerarContraseña = {this.onCancelarRegenerarContraseña}
            />

            {this.state.valueUuId && <SignOtpModalContainer
                otpType={this.state.otpType}
                valueUuId={this.state.valueUuId!}
                email={this.state.email}
                dispositivo={this.state.dispositivoMovil}
                key={this.state.signOTPkey}
                visible={this.state.signOtpVisible}
                oncloseSignOTP={this.oncloseSignOTP}
                tryAgain={this.tryAgain}
                sendByEmail={() => this.onNewOtpRequest(true)}
                onValidationSuccess={this.onValidOTP} /> }

            <CollegeErrorModal
                key={this.state.collegeErrorKey}
                visible={this.state.collegeErrorVisible}
                onClose={this.onCloseCollegeError} />
            
        </>

        );
    }

    private validationFailed = () => {
        this.setState({warningMessage: this.props.t('emptyFieldsWarning'), errorMessage: undefined});
    }

    private onValidationOtp = () => {
        this.setState({warningMessage: this.props.t('emptyFieldOtpsWarning'), errorMessage: undefined});
    }

    private onCloseModal = () => {
        this.setState({valueUuId:undefined,warningMessage:undefined,errorFields:undefined,errorMessage:undefined});
        this.props.onClose!();
    }
    
    private onCancelarRegenerarContraseña = () => {
        this.setState({forgotPasswordVisible:false});
    }

    private login = (values: Store) => {
        var username: string =  values.username;
        var password: string = values.password;
        Rest<LoginOperation, LoginOperationResponse>().operation({type: 'Login', login: username.replace(/\s/g, ""), password: password.replace(/\s/g, "")})
        .then(response => {
            if (response && response.valid && response.isLocal) {
                this.props.history.push('/app');
            } else if (response && response.valid && response.valueUuId) {
                this.setState({valueUuId: response.valueUuId, email: response.email, errorMessage: undefined, warningMessage: undefined, signOtpVisible: true, signOTPkey: new Date().getTime(), otpType: response.otpType, dispositivoMovil: response.dispositivoMovil});
            } else if (response && response.codeResult === EnumCodeResultLogin.SUSCRIPCION_CADUCADA) {
                this.setState({linkRenovacion: response.linkRenovacion, valueUuId: undefined, email: undefined, errorMessage: this.props.t('codeResultLogin:'+response.codeResult), warningMessage: undefined, codeResult:response.codeResult});
            } else {
                if (response && response.numAttempts && response.codeResult) {
                    this.setState({errorMessage: this.props.t('codeResultLogin:'+response.codeResult, {nIntentos: response.numAttempts}), warningMessage: undefined});
                } else {
                    this.setState({errorMessage: this.props.t('codeResultLogin:' + (response && response.codeResult ? response.codeResult : EnumCodeResultLogin.USUARIO_CONTRASENA_ERROR_TYPE_MODULO_EXISTENTE_PRIMER_INTENTO)), warningMessage: undefined})
                }
            }
        });
    }

    private tryAgain = () => {
        this.setState({errorMessage: undefined, warningMessage: undefined, codeResult: undefined, 
            valueUuId: undefined, signOtpVisible: false, linkRenovacion: undefined})
    }

    private onClickForgotPassword = (close?: boolean) => {
        this.setState({forgotPasswordVisible: !close, warningPasswordMessage: undefined, errorPasswordMessage: undefined, mailSent: undefined});
    }

    private closePasswordForm = () => {
        this.setState({forgotPasswordVisible: false, warningPasswordMessage: undefined, errorPasswordMessage: undefined, mailSent: undefined});
    }
    
    private onForgotPassword = (values: Store) => {
        Rest<{type: string, userName: string}, number>().operation({type: 'RegeneratePassword', userName: values.username})
        .then(response => {
            if (response === 1) {
                this.setState({errorPasswordMessage: this.props.t('passwordUserError')});
            }
            if (response === 2) {
                this.setState({errorPasswordMessage: this.props.t('passwordUserBloqError')});
            }
            if (response === 0) {
                this.setState({mailSent: true, errorPasswordMessage: undefined, warningPasswordMessage: undefined});
            }
        });
    }

    private validationPasswordFailed = () => {
        this.setState({warningPasswordMessage: this.props.t('emptyFieldPasswordWarning')});
    }

    private onCloseCollegeError = () => {
        this.setState({collegeErrorVisible: false});
    }

    private onOtpCancel = () => {
        this.setState({valueUuId: undefined, codeResult: undefined, otpCancelled: true, errorMessage: undefined, warningMessage: undefined});
    }

    private onNewOtpRequest = (useEmail?: boolean) => {
        if(typeof this.state.valueUuId === 'undefined') {
            return;
        }

        Rest<{type: string, valueUuId: string, useEmail?: boolean}, OtpCallerProps>()
            .operation({type: 'ResendOtpCode', valueUuId: this.state.valueUuId, useEmail: useEmail})
            .then(response => {
                if(response) {
                    this.setState({valueUuId:  response.valueUuId, email: response.email, otpType: response.otpType, 
                        dispositivoMovil: response.dispositivoMovil, signOtpVisible: true, signOTPkey: new Date().getTime(),
                        errorMessage: undefined, warningMessage: undefined});
                    notification['success']({
                        message: this.props.t('codigoReenviado')
                    });
                } else {
                    this.setState({valueUuId:  undefined, errorMessage: undefined, warningMessage: undefined});
                }
            });
    }

    private onValidOTP = (valueUuId: string, codeOtp?: string) => {
        Rest<{type: string, valueUuId: string, codeOtp?: string}, LoginOperationResponse>().operation({
            type: 'Start',
            valueUuId: valueUuId,
            codeOtp: codeOtp
        }).then(response => {
            if (response.codeResult === EnumCodeResultLogin.ACCESO_NO_PERMITIDO) {
                this.setState({collegeErrorVisible: true, collegeErrorKey: new Date().getTime(), valueUuId: undefined, signOtpVisible: false});
            } else {
                this.props.history.push('/app');
            }
        });
    }

    private oncloseSignOTP = () => {
        this.setState({ signOtpVisible: false });
    }

}

export default withTranslation('login')(withRouter(LoginModalContainer));