import React from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { Functions, Rest } from '../../utils/utils';
import Medicine from './entities/medicine';
import OtherDetailsInfo from './entities/otherDetailsInfo';
import Posology from './entities/posology';
import OtherDetailsContainer from './medicine/OtherDetailsContainer';
import PosologyConfigContainer from './medicine/PosologyConfigContainer';
import PrescriptionConfigContainer from './medicine/PrescriptionConfigContainer';
import SelectMedicineContainer from './medicine/SelectMedicineContainer';
import _ from 'lodash';
import { notification } from 'antd';
import Dcpf from './entities/dcpf';
import alertsSummaryEditingPrescription from './entities/alertsSummaryEditingPrescription';
import { FormInstance } from 'antd/es/form/Form';
import PrestadorConfigContext from '../../context/PrestadorConfigContext';

interface IProps {
    visible?: boolean;
    idPatient?: number;
    idLocation?: number;
    idDiagnostico?: number;
    codeDiagnostico?: string;
    typePrescription?: number;
    idPrescriptionGroup?: number;
    idPrescriptionMod?: number;
    flagPacienteMutualista?: boolean;
    onClose: (idPrescription?: number) => void;
    setMedicine: (medicine: any) => Medicine;
}

export interface IState {
    prescriptionConfigVisible?: boolean;
    prescriptionConfigKey?: number;
    medicineSelected?: any;
    otherProductSelected?: any;
    dcpfSelected?: Dcpf;
    selectMedicineVisible?: boolean;
    selectMedicineKey?: number;
    posologySelected?: Posology;
    posologyVisible?: boolean;
    posologyKey?: number;
    otherDetails?: OtherDetailsInfo;
    otherDetailsVisible?: boolean;
    otherDetailsKey?: number;
    productSpecification?: string;

    alertsRefresh: number;
    alertsDismiss?: boolean;
    alertsSave?: boolean;
}

class SavePrescriptionContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public state: IState = {
        alertsRefresh: 0
    };

    public componentDidMount() {
        if (this.props.visible) {
            if (this.props.idPrescriptionMod) {
                this.loadData();
            } else {
                this.setState({prescriptionConfigVisible: true, prescriptionConfigKey: new Date().getTime()});
            }
        }
    }
    static contextType = PrestadorConfigContext;

    public render() {
        return (
            <>
                <PrescriptionConfigContainer
                    key={this.state.prescriptionConfigKey}
                    visible={this.state.prescriptionConfigVisible}
                    idPatient={this.props.idPatient}
                    idLocation={this.props.idLocation}
                    idDiagnostico={this.props.idDiagnostico}
                    codeDiagnostico={this.props.codeDiagnostico}
                    typePrescription={this.props.typePrescription}
                    idPrescriptionGroup={this.props.idPrescriptionGroup}
                    medicineSelected={this.state.medicineSelected}
                    otherProductSelected={this.state.otherProductSelected}
                    dcpfSelected={this.state.dcpfSelected}
                    posologySelected={this.state.posologySelected}
                    otherDetails={this.state.otherDetails}
                    productSpecification={this.state.productSpecification}
                    idPrescription={this.props.idPrescriptionMod}
                    onClose={this.onClosePrescriptionConfig}
                    onClickSelectMedicine={this.onClickSelectMedicine}
                    onClickPosologyConfig={this.onClickPosologyConfig}
                    onClickOtherDetails={this.onClickOtherDetails}
                    saveSpecification={this.saveSpecification}
                    getEditingPrescription = {this.getEditingPrescription}
                    alertsRefresh = {this.state.alertsRefresh}
                    setMedicine={this.props.setMedicine} 
                   />

                <SelectMedicineContainer
                    key={this.state.selectMedicineKey}
                    visible={this.state.selectMedicineVisible}
                    typePrescription={this.props.typePrescription}
                    onClose={this.onCloseSelectMedicine}
                    idPrescriptionGroup={this.props.idPrescriptionGroup}
                    setMedicine={this.props.setMedicine} 
                    modifyFlag = {this.props.idPrescriptionMod ? true: false}
                    flagPacienteMutualista={this.props.flagPacienteMutualista}
                    />

                <PosologyConfigContainer
                    key={this.state.posologyKey}
                    visible={this.state.posologyVisible}
                    idPatient={this.props.idPatient}
                    codeDiagnostico={this.props.codeDiagnostico}
                    idPrescriptionGroup={this.props.idPrescriptionGroup}
                    medicine={this.state.medicineSelected}
                    otherProduct={this.state.otherProductSelected}
                    dcpf={this.state.dcpfSelected}
                    posologySelected={this.state.posologySelected}
                    productSpecification={this.state.productSpecification}
                    alertsRefreshFlag={this.state.alertsRefresh}
                    alertsRefresh={() => {this.setState({alertsRefresh: this.state.alertsRefresh+1});}}
                    onClose={this.onClosePosology}
                    setMedicine={this.props.setMedicine} 
                    getEditingPrescription = {this.getEditingPrescription}/>

                <OtherDetailsContainer
                    key={this.state.otherDetailsKey}
                    visible={this.state.otherDetailsVisible}
                    medicine={this.state.medicineSelected}
                    otherProduct={this.state.otherProductSelected}
                    dcpf={this.state.dcpfSelected}
                    otherDetails={this.state.otherDetails}
                    setMedicine={this.props.setMedicine} 
                    productSpecification={this.state.productSpecification}
                    onClose={this.onCloseOtherDetails} />
            </>

        )
    }

    private loadData = () => {
        Rest<{ type: string, idPrescripcion: number }, any>()
        .operation({
            type: 'GetEditPrescriptionDetails',
            idPrescripcion: this.props.idPrescriptionMod!,
        }).then(response => {
            this.setData(response);
        });
    }

    private setData = (data: any) => {
        let productSpecification;
        let medicineSelected;
        let dcpfSelected;
        let otherProductSelected;

        if (data.prescripcion[0].idVademecum) {
            const medicine = data.prescripcion[0];
            medicineSelected = {
                idVademecum: medicine.idVademecum,
                codeCodigoNacional: medicine.codeCodigoNacional,
                nameProducto: medicine.valuePresentacion,
                laboratory: medicine.valueLaboratorio,
                dosage: medicine.valueDosisMed,
                psychotropic: !!medicine.flagPsicotropo,
                narcotic: !!medicine.flagEstupefaciente,
                codePharmaceuticalForm: medicine.codeFormaFarmaceutica,
                namePharmaceuticalForm: medicine.valueFormaFarmaceutica,
                codeUnitContent: medicine.codeUnidadContenido,
                valueUnitContent: medicine.valueUnidadContenido,
                codeDcpf: medicine.codeDcpf,
                nameDcpf: medicine.nameDcpf,
                flagDetallePorBaja: medicine.flagDetallePorBaja,
                activePrinciples: _(data.prescripcion).groupBy('codePrincipioActivo').map((value) => ({ codeActivePrinciple: value[0].codePrincipioActivo, nameActivePrinciple: value[0].valuePrincipioActivo})).value(),
                vias: _(data.prescripcion).groupBy('codeViaAdministracion').map((value) => ({ codeVia: value[0].codeViaAdministracion, nameVia: value[0].valueViaAdministracion})).value(),
                docs: medicine.idVademecumDocumento && _(data.prescripcion).groupBy('idVademecumDocumento').map((value) => ({ idDocument: value[0].idVademecumDocumento, urlDocument: value[0].valueUrl})).value(),
                photos: medicine.idVademecumImagen && _(data.prescripcion).groupBy('idVademecumImagen').map((value) => ({ idImage: value[0].idVademecumImagen, image: value[0].valueImagen})).value()
            };
            
        } else if(data.dcpf) {
            dcpfSelected = data.dcpf;
        } else if (data.prescripcion[0].packageId) {
            otherProductSelected = {
                packageId: data.prescripcion[0].packageId,
                nameProducto : data.prescripcion[0].valuePresentacion,
                valueUnidadContenido: data.prescripcion[0].valueUnidadContenido,
                descUnidadContenido: data.prescripcion[0].descUnidadContenido,
                codeCodigoNacional: data.prescripcion[0].codeCodigoNacional
            }
           
        }else {
            productSpecification = data.prescripcion[0].valueComposicionFmv;
            otherProductSelected = {
                codeCodigoNacional: data.prescripcion[0].codeCodigoNacional,
                nameProducto : data.prescripcion[0].valuePresentacion,
                valueUnidadContenido: data.prescripcion[0].valueUnidadContenido,
                descUnidadContenido: data.prescripcion[0].descUnidadContenido
            }
        }

        const posologySelected = {
            dateStart: data.prescripcion[0].dateInicioTratamiento,
            dateStartString: Functions.dateToString(data.prescripcion[0].dateInicioTratamiento),
            duration: data.prescripcion[0].valueDuracion,
            dosage: data.prescripcion[0].valueDosis,
            frequency: data.prescripcion[0].valueFrecuencia,
            frequencyType: data.prescripcion[0].typeFrecuencia,
            packageDuration: data.prescripcion[0].valueDuracionEnvaseDias,
            nPackages: data.prescripcion[0].nbrEnvasesTotal,
            patientIndications: data.prescripcion[0].descIndicacionesPaciente,
            pharmacyIndications: data.prescripcion[0].descIndicacionesFarmaceutico,
            typePosology: data.prescripcion[0].typePosology,
            typePosologiaEspecial: data.prescripcion[0].typePosologiaEspecial,
            textoPosology: data.prescripcion[0].textoPosology,
            idUnidad: data.prescripcion[0].idUnidad
        };

        const otherDetails = {
            pin: data.prescripcion[0].codeConfidencial,
            isVisado: !!data.prescripcion[0].flagVisado,
            visadoStart: data.prescripcion[0].dateIniVisado,
            visadoEnd: data.prescripcion[0].dateFinVisado,
            visadoInput: data.prescripcion[0].pctRegAportacion,
        };

        this.setState({medicineSelected, dcpfSelected, otherProductSelected, productSpecification, prescriptionConfigVisible: true, prescriptionConfigKey: new Date().getTime(), posologySelected, otherDetails});
    }

    private onClosePrescriptionConfig = (idPrescription?: number) => {
        this.setState({ prescriptionConfigVisible: false}, () => this.props.onClose(idPrescription));
    }

    private onClickSelectMedicine = (change?: boolean) => {
        this.setState({ selectMedicineVisible: true, selectMedicineKey: change ? this.state.selectMedicineKey : new Date().getTime(), prescriptionConfigVisible: false });
    }

    private onClickPosologyConfig = () => {
        if (!this.state.medicineSelected && !this.state.productSpecification && !this.state.otherProductSelected && !this.state.dcpfSelected) {
            notification['info']({
                message: this.props.t('posologyError')
            });
            return;
        }
        this.setState({ posologyVisible: true, prescriptionConfigVisible: false, posologyKey: new Date().getTime() });
    }

    private onClickOtherDetails = () => {
        if (!this.state.medicineSelected && !this.state.productSpecification && !this.state.dcpfSelected && !this.state.otherProductSelected) {
            return;
        }
        this.setState({ otherDetailsVisible: true, prescriptionConfigVisible: false, otherDetailsKey: new Date().getTime()});
    }

    private onCloseSelectMedicine = (medicine?: Medicine, otherProduct?: any, dcpf?: Dcpf) => {
        this.setState({
            selectMedicineVisible: false,
            prescriptionConfigVisible: true,
            medicineSelected: dcpf ? undefined : (medicine ? medicine : this.state.medicineSelected),
            dcpfSelected: medicine ? undefined : (dcpf ? dcpf : this.state.dcpfSelected),
            otherProductSelected: otherProduct ? otherProduct : this.state.otherProductSelected});
        if(medicine || dcpf){ 
            this.setState({alertsRefresh: this.state.alertsRefresh+1, posologySelected: undefined, otherDetails: undefined});
        } else if (otherProduct){
            this.setState({posologySelected: undefined});
        }
    }

    private onClosePosology = (posology?: Posology) => {

        //Para mostrar cambios de datos al vuelo pedir cadena al back
        if(posology){
            Rest<{ type: string, idIdioma?: any,
                typePosology?: number, typeDuracion?: number, dateInicioPrescripcion?: Date, valueDuracion?: number, valueDosis?: number, typeFrecuencia?: number, valueFrecuencia?: number,
                typePosologia?: number, valueUnidadContenido?: number, typePosologiaEspecial?: number, idUnidad?: number}, any>().operation({
                type: 'GenerateTextoPosologiaFront',
                idIdioma: null,
                typeDuracion: 1,
                valueDuracion: posology.duration,
                valueDosis: posology.dosage,
                valueUnidadContenido: this.state.medicineSelected ? this.state.medicineSelected.valueUnitContent : (this.state.dcpfSelected ? this.state.dcpfSelected.valueUnidadContenido : (this.state.productSpecification ? null : this.state.otherProductSelected.valueUnidadContenido)),
                typeFrecuencia: posology.frequencyType,
                valueFrecuencia: posology.frequency,
                dateInicioPrescripcion: posology.dateStart,
                typePosologia: posology.typePosology,
                typePosologiaEspecial: posology.typePosologiaEspecial,
                idUnidad: posology.idUnidad
            }).then(response => {
                
                this.setState({posologyVisible: false, prescriptionConfigVisible: true, posologySelected: {...posology,textoPosology: response},alertsRefresh: this.state.alertsRefresh+1});
            });
        } else {
            this.setState({posologyVisible: false, prescriptionConfigVisible: true, posologySelected: this.state.posologySelected});
        }
    }

    private onCloseOtherDetails = (details?: OtherDetailsInfo) => {
        this.setState({ otherDetailsVisible: false, prescriptionConfigVisible: true, otherDetails: details ? details : this.state.otherDetails });
    }

    private saveSpecification = (specification: string) => {
        this.setState({productSpecification: specification});
    }

    private getEditingPrescription =  (form?: FormInstance) => {
        var editingPrescription = null;
        if(form==undefined && (this.state.medicineSelected!=null || this.state.dcpfSelected!=null)){
            editingPrescription = {
                idPrescripcion: this.props.idPrescriptionMod ? this.props.idPrescriptionMod: undefined,
                codeCodigoNacional: this.state.medicineSelected? this.state.medicineSelected.codeCodigoNacional: undefined,
                codeDCPF: this.state.dcpfSelected? this.state.dcpfSelected.codeDcpf: undefined,
                typeFrecuencia: this.state.posologySelected? this.state.posologySelected.frequencyType: undefined,
                valueFrecuencia: this.state.posologySelected? this.state.posologySelected.frequency: undefined,
                valueDosis: this.state.posologySelected? this.state.posologySelected.dosage:undefined,
                valueDuracion: this.state.posologySelected? this.state.posologySelected.duration:undefined,
                typeDuracion: 1,
                typePosology: this.state.posologySelected ? this.state.posologySelected.typePosology: undefined,
                dateInicioTratamiento: this.state.posologySelected? this.state.posologySelected.dateStart:undefined,
                typePosologiaEspecial: this.state.posologySelected ? this.state.posologySelected.typePosologiaEspecial : undefined,
                idUnidad: this.state.posologySelected?.idUnidad
            } as alertsSummaryEditingPrescription;
        }else if(form!=undefined){
            const values = form.getFieldsValue();
            editingPrescription = {
                idPrescripcion: this.props.idPrescriptionMod ? this.props.idPrescriptionMod: undefined,
                codeCodigoNacional: this.state.medicineSelected? this.state.medicineSelected.codeCodigoNacional: undefined,
                codeDCPF: this.state.dcpfSelected? this.state.dcpfSelected.codeDcpf: undefined,
                typeFrecuencia: values.frequencyType,
                valueFrecuencia: (values.frequency && values.dosage && values.duration && values.dateStart) ? values.frequency:undefined,
                valueDosis: (values.frequency && values.dosage && values.duration && values.dateStart) ? values.dosage:undefined,
                valueDuracion: (values.frequency && values.dosage && values.duration && values.dateStart) ? values.duration:undefined,
                typeDuracion: 1,
                dateInicioTratamiento: (values.frequency && values.dosage && values.duration && values.dateStart) ? values.dateStart:undefined,
                typePosologiaEspecial: values.typePosologiaEspecial,
                idUnidad: values.idUnidad
            } as alertsSummaryEditingPrescription;
        }
        return editingPrescription
    }

}

export default withTranslation('medicineConfig')(withRouter(SavePrescriptionContainer));