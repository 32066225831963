import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Col, Form, FormInstance, Modal, Row, Space } from 'antd';
import TextArea from 'antd/lib/input/TextArea';
import moment from 'moment';
import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import EnumPrescriptionType from '../../../enum/EnumPrescriptionType';
import Dcpf from '../entities/dcpf';
import Medicine from '../entities/medicine';
import OtherDetailsInfo from '../entities/otherDetailsInfo';
import Posology from '../entities/posology';

import { IState as IStateContainer } from './PrescriptionConfigContainer';
import PrescriptionAlertsPanelContainer from '../../alerts/PrescriptionAlertsPanelContainer';
import PrestadorConfigContext from '../../../context/PrestadorConfigContext';
import MedicineInfoPopOver from './MedicineInfoPopOver';

const PrescriptionConfig = (props: {
    visible?: boolean;
    medicineSelected?: Medicine;
    otherProductSelected?: any;
    idPatient?: number;
    codeDiagnostico?: string;
    idPrescriptionGroup?: number;
    dcpfSelected?: Dcpf;
    posologySelected?: Posology;
    otherDetails?: OtherDetailsInfo;
    typePrescription?: number;
    productSpecification?: string;
    onCancel: () => void;
    onClickPosologyConfig: () => void;
    onClickOtherDetails: () => void;
    savePrescription: () => void;
    saveSpecification: (form: FormInstance) => void;
    onCloseProduct: () => void;
    onClickSelectMedicineProduct: (change?: boolean) => void;
    getEditingPrescription: (form?: FormInstance) => any;
    alertsRefresh: number;
    alertsDismiss?: boolean;
    alertsSave?: boolean;
    setMedicine: (medicine: any) => Medicine;
} & IStateContainer) => {

    const { t } = useTranslation(['medicineConfig']);
    
    const ctxValue = useContext(PrestadorConfigContext);
    const vidalEnabled = ctxValue.data.vidalEnabled;
    
    const modalTitle = () => {
        switch(Number(props.typePrescription)) {
            case EnumPrescriptionType.MEDICAMENTO:
                return t('configMedicineTitle');
            case EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO:
                return t('configProductTitle') + ' ' + t('cataloged');
            case EnumPrescriptionType.NUTRICION_CATALOGADA:
                return t('configNutritionTitle') + ' ' + t('cataloged');
            case EnumPrescriptionType.VACUNA_CATALOGADA:
                return t('configVaccineTitle') + ' ' + t('cataloged');
            case EnumPrescriptionType.VACUNA:
                return t('configVaccineTitle') + ' ' + t('noCataloged');
            case EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA:
                return t('configFormulaTitle') + ' ' + t('cataloged');
            case EnumPrescriptionType.NUTRICION:
                return t('configNutritionTitle') + ' ' + t('noCataloged');
            case EnumPrescriptionType.PRODUCTO_SANITARIO:
                return t('configProductTitle') + ' ' + t('noCataloged');
            default:
                return t('configFormulaTitle') + ' ' + t('noCataloged');
        }
    }

    return (
        <Modal title={modalTitle()}
            style={{ top: 40 }}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            onOk={() => { props.savePrescription(); return true}}
            bodyStyle={{paddingBottom: '1.5rem'}}
            width={800}
            okText={t('buttons:save')} cancelText={t('buttons:cancel')}
            destroyOnClose
            maskClosable={false}
        >
            <Row className='new_prescription_container' gutter={[24, 24]}>
                <Col span={24}>
                    {!props.medicineSelected && !props.productSpecification && !props.otherProductSelected &&  !props.dcpfSelected ?
                        <Row align='middle' className='data_container'>
                            <div className='number'>1</div>
                            <span className='text_title'>
                                {Number(props.typePrescription) === EnumPrescriptionType.MEDICAMENTO ? t('selectMedicineTitle') : t('selectProductTitle')}
                            </span>
                            {!props.productFieldVisible &&
                                <Button type="link" className='link_title' onClick={() => props.onClickSelectMedicineProduct()}>
                                    {Number(props.typePrescription) === EnumPrescriptionType.MEDICAMENTO ? t('selectMedicineButton') : t('selectProductButton')}
                                </Button>}
                            {props.productFieldVisible && 
                                <div style={{width: '100%'}}>
                                    <SelectProduct
                                        productSpecification={props.productSpecification}
                                        cancel={props.onCloseProduct}
                                        saveSpecification={props.saveSpecification} />
                                </div>}
                        </Row>
                    : <div className='data_container'>
                        <Row align='middle'>
                            <FontAwesomeIcon icon={faCheckCircle} className='number'/>
                            <span className='text_title'>
                                {Number(props.typePrescription) === EnumPrescriptionType.MEDICAMENTO ? t('selectMedicineTitle') : t('selectProductTitle')}
                            </span>
                            {!props.productFieldVisible && 
                                    <Button type="link" className='link_title' onClick={() => props.onClickSelectMedicineProduct(true)}>
                                        {Number(props.typePrescription) === EnumPrescriptionType.MEDICAMENTO ? t('changeMedicineButton') : t('changeProductButton')}
                                    </Button>}
                        </Row>
                        {props.productFieldVisible ?
                            <div style={{width: '100%'}}>
                                <SelectProduct
                                    productSpecification={props.productSpecification}
                                    cancel={props.onCloseProduct}
                                    saveSpecification={props.saveSpecification} />
                            </div>
                            :
                                <div>
                                    {props.productSpecification ?
                                        <div style={{fontSize: '1rem', fontWeight: 'bold'}}>{props.productSpecification}</div>
                                    :
                                    props.dcpfSelected ?
                                        <div className='medication-card__container'>
                                            <div className='medication-card-info'>
                                                <div className='medication-info'>
                                                    <div className='medication-name'>
                                                        {props.dcpfSelected?.nameDcpf}
                                                    </div>
                                                    <div className='medication-cn'>{t('codeDcpf') + ' ' + props.dcpfSelected?.codeDcpf}</div>
                                                </div>
                                                
                                            </div>
                                            {props.idPatient && vidalEnabled && <div className={"alertsContainer "}>
                                                <PrescriptionAlertsPanelContainer
                                                visible={true}
                                                idPatient={props.idPatient}
                                                idPrescriptionGroup={props.idPrescriptionGroup}
                                                codeDiagnostico={props.codeDiagnostico}
                                                calledFrom={"prescription"}
                                                editingPrescription={props.getEditingPrescription()}
                                                refresh={props.alertsRefresh}
                                                dismiss={props.alertsDismiss}
                                                save={props.alertsSave}
                                                level={2}
                                                />
                                            </div>}
                                        </div>
                                    :
                                    props.medicineSelected ? 
                                        <div className='medication-card__container'>
                                            <MedicineInfoPopOver
                                                visible={true}
                                                setMedicine={props.setMedicine}
                                                medicine={props.medicineSelected}
                                            />
                                            {props.idPatient && vidalEnabled && <div className={"alertsContainer "}>
                                                <PrescriptionAlertsPanelContainer
                                                visible={true}
                                                idPatient={props.idPatient}
                                                idPrescriptionGroup={props.idPrescriptionGroup}
                                                codeDiagnostico={props.codeDiagnostico}
                                                calledFrom={"prescription"}
                                                editingPrescription={props.getEditingPrescription()}
                                                refresh={props.alertsRefresh}
                                                dismiss={props.alertsDismiss}
                                                save={props.alertsSave}
                                                level={2}
                                                />
                                            </div>}
                                        </div>
                                    :
                                    <div className='medication-card__container'>
                                        <div className='medication-card-info'>
                                            <div className='medication-info'>
                                                <div className='medication-name'>
                                                    {props.otherProductSelected.nameProducto}
                                                </div>
                                              
                                            </div>
                                            
                                        </div>
                                    </div>
                                    }   
                                </div>
                            }
                        </div>
                    }
                </Col>
                <Col span={24}>
                    {!props.posologySelected ?
                        <Row className='data_container' align='middle'>
                            <div className='number'>2</div>
                            <span className='text_title'>
                                {t('posologyTitle')}
                            </span>
                            <Button type="link" className='link_title' onClick={props.onClickPosologyConfig}>{t('posologyButton')}</Button>
                        </Row>

                    :
                        <div className='data_container'>
                            <Row align='middle'>
                                <FontAwesomeIcon icon={faCheckCircle} className='number'/>
                                <span className='text_title'>
                                    {t('posologyTitle')}
                                </span>
                                <Button type="link" className='link_title' onClick={() => props.onClickPosologyConfig()}>{t('changePosology')}</Button>
                            </Row>
                            <div>
                            <div>
                               {props.posologySelected.textoPosology && <span style={{paddingRight: '1rem'}}>{props.posologySelected.textoPosology}</span>}
                            </div>
                            <div><b style={{paddingRight: '1rem'}}>{t('posologySelectedPatientIndications')}</b> {props.posologySelected.patientIndications ? props.posologySelected.patientIndications : t('noneIndications') }</div>
                            <div><b style={{paddingRight: '1rem'}}>{t('posologySelectedPharmacyIndications')}</b> {props.posologySelected.pharmacyIndications ? props.posologySelected.pharmacyIndications : t('noneIndications')}</div>
                            </div>
                        </div>
                    }
                </Col>
                {props.idPatient && <Col span={24}>
                {(!props.otherDetails || (!props.otherDetails.isVisado && !props.otherDetails.pin && !props.otherDetails.visadoEnd && !props.otherDetails.visadoStart && !props.otherDetails.visadoInput)) ?
                    <Row className='data_container' align='middle'>
                        <div className='number'>3</div>
                        <span className='text_title'>
                            {t('otherDetailsTitle')}
                        </span>
                        <Button type="link" className='link_title' onClick={props.onClickOtherDetails}>{t('otherDetailsButton')}</Button>
                        <span className='text_subtitle'><b>{t('otherDetailsSubOptional')}</b> <i>{t('otherDetailsSub')}</i></span>
                    </Row>
                :
                    <div className='data_container'>
                        <Row align='middle'>
                            <FontAwesomeIcon icon={faCheckCircle} className='number'/>
                            <span className='text_title'>
                                {t('otherDetailsTitle')}
                            </span>
                            <Button type="link" className='link_title' onClick={() => props.onClickOtherDetails()}>{t('changeOtherDetailsButton')}</Button>
                            <span className='text_subtitle'><b>{t('otherDetailsSubOptional')}</b> <i>{t('otherDetailsSub')}</i></span>
                        </Row>
                        <div>
                            <b>{t('confidencialLabel')}</b> {props.otherDetails.pin ? t('yes') : t('no')} {props.otherDetails.pin && <>{t('confidencialPin')} <b>{props.otherDetails.pin}</b></>}
                            {props.otherDetails.isVisado &&
                            <div>
                                <b>{t('detailVisado')}</b> {props.otherDetails.isVisado ? t('yes') : t('no')}. {props.otherDetails.visadoInput && <>{t('aportacionVisado')} <b>{props.otherDetails.visadoInput}%</b> </>}
                                {props.otherDetails.visadoStart && <>{t('desdeVisado')} <b>{moment(props.otherDetails.visadoStart).format("L")}</b></>} {props.otherDetails.visadoEnd && <>{t('hastaVisado')} <b>{moment(props.otherDetails.visadoEnd).format("L")}</b></>}
                            </div>
                            }
                        </div>
                    </div>
                }
                </Col>}
            </Row>
        </Modal>
    )

}

const SelectProduct = (props: {
    productSpecification?: string;
    cancel: () => void;
    saveSpecification: (form: FormInstance) => void;
}) => {
    const [form] = Form.useForm();

    const { t } = useTranslation(['medicineConfig']);

    return (
        <Form layout='vertical' form={form} size='large' style={{padding: '1rem 0 0.5rem'}}>
            <Row gutter={8}>
                <Col span={24}>
                    <Form.Item name='productSpecification' rules={[{ required: true }]} initialValue={props.productSpecification}>
                        <TextArea rows={6} style={{resize: 'none'}}/>
                    </Form.Item>
                </Col>
            </Row>

            <Row justify='end'>
                <Space size='middle'>
                    <Button size='middle' onClick={props.cancel}>{t('buttons:cancel')}</Button>
                    <Button size='middle' type='primary' onClick={() => props.saveSpecification(form)}>{t('buttons:save')}</Button>
                </Space>
            </Row>
        </Form>
    )
}
export default PrescriptionConfig;