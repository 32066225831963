import { FormInstance, notification } from 'antd';
import React from 'react';

import { WithTranslation, withTranslation } from 'react-i18next';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import EnumPrescriptionType from '../../../enum/EnumPrescriptionType';
import { Rest } from '../../../utils/utils';
import Dcpf from '../entities/dcpf';
import Medicine from '../entities/medicine';
import OtherDetailsInfo from '../entities/otherDetailsInfo';
import Posology from '../entities/posology';
import View from './PrescriptionConfig';

interface IProps {
    visible?: boolean;
    idPatient?: number;
    idLocation?: number;
    idDiagnostico?: number;
    codeDiagnostico?: string;
    typePrescription?: number;
    idPrescriptionGroup?: number;
    medicineSelected?: Medicine;
    otherProductSelected?: any;
    dcpfSelected?: Dcpf;
    posologySelected?: Posology;
    otherDetails?: OtherDetailsInfo;
    productSpecification?: string;
    idPrescription?: number;
    alertsRefresh?: number;
    onClose: (idPrescriptionGroup?: number) => void;
    onClickSelectMedicine: (change?: boolean) => void;
    onClickPosologyConfig: () => void;
    onClickOtherDetails?: () => void;
    saveSpecification: (specification: string) => void;
    getEditingPrescription?: (form?: FormInstance) => any;
    setMedicine: (medicine: any) => Medicine;
}

export interface IState {
    productFieldVisible?: boolean;
    productFieldKey?: boolean;
    alertsDismiss?: boolean;
    alertsSave?: boolean;
}

class PrescriptionConfigContainer extends React.Component<IProps & WithTranslation & RouteComponentProps, IState> {

    public render() {
        return (
            <View
                {...this.state}
                visible={this.props.visible}
                medicineSelected={this.props.medicineSelected}
                otherProductSelected={this.props.otherProductSelected}
                idPatient={this.props.idPatient}
                codeDiagnostico={this.props.codeDiagnostico}
                idPrescriptionGroup={this.props.idPrescriptionGroup}
                dcpfSelected={this.props.dcpfSelected}
                productSpecification={this.props.productSpecification}
                posologySelected={this.props.posologySelected}
                otherDetails={this.props.otherDetails}
                typePrescription={this.props.typePrescription}
                onCancel={this.onClose}
                onClickPosologyConfig={this.props.onClickPosologyConfig}
                onClickOtherDetails={this.props.onClickOtherDetails!}
                savePrescription={this.savePrescription}
                saveSpecification={this.saveSpecification}
                onCloseProduct={this.onCloseProduct}
                setMedicine={this.props.setMedicine}
                onClickSelectMedicineProduct={this.onClickSelectMedicineProduct} 
                getEditingPrescription={this.props.getEditingPrescription!}
                alertsRefresh={this.props.alertsRefresh!}/>
        )
    }

    private savePrescription = () => {
        if ((!this.props.medicineSelected && !this.props.productSpecification && !this.props.otherProductSelected && !this.props.dcpfSelected) || !this.props.posologySelected) {
            notification['error']({
                message: this.props.t('saveError')
            });
            return;
        }

        if (this.props.idPrescription) {
            this.modifyPrescription();
            return;
        }

        const typePrescription = () => {
            if (Number(this.props.typePrescription) === EnumPrescriptionType.VACUNA_CATALOGADA) {
                return EnumPrescriptionType.VACUNA;
            }
            if (Number(this.props.typePrescription) === EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA) {
                return EnumPrescriptionType.FORMULA_MAGISTRAL;
            }
            if (Number(this.props.typePrescription) === EnumPrescriptionType.NUTRICION_CATALOGADA) {
                return EnumPrescriptionType.NUTRICION;
            }
            if (Number(this.props.typePrescription) === EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO) {
                return EnumPrescriptionType.PRODUCTO_SANITARIO;
            }
            return this.props.typePrescription!;
        }

        let otherDetailsVisadoInput = this.props.otherDetails? this.props.otherDetails.visadoInput : undefined;
        if(typeof otherDetailsVisadoInput !== 'undefined'
            && otherDetailsVisadoInput.toString().length === 0
        ) {
            otherDetailsVisadoInput = undefined;
        }


        Rest<{ type: string, idPrescripcionGrupo?: number, idPatient: number, idLocation: number, idDiagnostico: number,typePrescription: number, idVademecum?: number, codeDcpf?: string,
            productSpecification?: string, typePosology?: number, dateStart: Date, duration: number, dosage: number, frequency: number, frequencyType: number, packageDuration: number, nPackages: number,
            patientIndications: string, pharmacyIndications?: string, pin?: string, isVisado?: boolean, visadoStart?: Date, visadoEnd?: Date, visadoInput?: number, packageId?: number,
            idAccesoryEffect?: string, nameAccesoryEffect?: string, typePosologiaEspecial?: number, idUnidad?: number}, any>().operation({
            type: this.props.idPatient ? 'SavePrescription' : 'SavePrescriptionFav',
            idPrescripcionGrupo: this.props.idPrescriptionGroup,
            idDiagnostico: this.props.idDiagnostico!,
            idPatient: this.props.idPatient!,
            idLocation: this.props.idLocation!,
            typePrescription: typePrescription(),
            idVademecum: this.props.medicineSelected && this.props.medicineSelected!.idVademecum,
            codeDcpf: this.props.dcpfSelected && this.props.dcpfSelected.codeDcpf,
            productSpecification: this.props.productSpecification,
            typePosology: this.props.posologySelected!.typePosology!,
            dateStart: this.props.posologySelected!.dateStart!,
            duration: this.props.posologySelected!.duration,
            dosage: this.props.posologySelected!.dosage,
            frequency: this.props.posologySelected!.frequency,
            frequencyType: this.props.posologySelected!.frequencyType,
            packageDuration: this.props.posologySelected!.packageDuration,
            nPackages: this.props.posologySelected!.nPackages,
            patientIndications: this.props.posologySelected!.patientIndications,
            pharmacyIndications: this.props.posologySelected!.pharmacyIndications,
            pin: this.props.otherDetails?.pin,
            isVisado: this.props.otherDetails?.isVisado,
            visadoStart: this.props.otherDetails?.visadoStart,
            visadoEnd: this.props.otherDetails?.visadoEnd,
            visadoInput: otherDetailsVisadoInput,
            packageId: this.props.otherProductSelected?.packageId,
            idAccesoryEffect: this.props.otherProductSelected?.codeCodigoNacional,
            nameAccesoryEffect: this.props.otherProductSelected?.nameProducto,
            typePosologiaEspecial: this.props.posologySelected!.typePosologiaEspecial,
            idUnidad: this.props.posologySelected!.idUnidad
        }).then(response => {
            if(this.props.idPatient){
                this.setState({alertsSave: true}, () => {
                    this.props.onClose(response.idPrescripcionGrupo);
                });
            }else{
                this.props.onClose(response);
            }
        });
    }

    private modifyPrescription = () => {
        let otherDetailsVisadoInput = this.props.otherDetails? this.props.otherDetails.visadoInput : undefined;
        if(typeof otherDetailsVisadoInput !== 'undefined'
            && otherDetailsVisadoInput.toString().length === 0
        ) {
            otherDetailsVisadoInput = undefined;
        }

        Rest<{ type: string, idPrescripcion?: number, idPrescripcionFav?: number, idLocation: number, idVademecum?: number, codeDcpf?: string,
            productSpecification?: string, typePosology?: number, dateStart: Date, duration: number, dosage: number, frequency: number, frequencyType: number, packageDuration: number,
            nPackages: number, patientIndications: string, pharmacyIndications?: string, pin?: string, isVisado?: boolean, visadoStart?: Date, visadoEnd?: Date, visadoInput?: number, 
            packageId?: number, idAccesoryEffect?: string, nameAccesoryEffect?: string, typePosologiaEspecial?: number, idUnidad?: number}, any>().operation({
            type: this.props.idPatient ?  'ModifyPrescription' : 'ModifyFavPrescription',
            idPrescripcion: this.props.idPrescription,
            idPrescripcionFav: this.props.idPatient ? undefined :this.props.idPrescription,
            idLocation: this.props.idLocation!,
            idVademecum: this.props.medicineSelected && this.props.medicineSelected!.idVademecum,
            codeDcpf: this.props.dcpfSelected && this.props.dcpfSelected.codeDcpf,
            productSpecification: this.props.productSpecification,
            typePosology: this.props.posologySelected!.typePosology!,
            dateStart: this.props.posologySelected!.dateStart!,
            duration: this.props.posologySelected!.duration,
            dosage: this.props.posologySelected!.dosage,
            frequency: this.props.posologySelected!.frequency,
            frequencyType: this.props.posologySelected!.frequencyType,
            packageDuration: this.props.posologySelected!.packageDuration,
            nPackages: this.props.posologySelected!.nPackages,
            patientIndications: this.props.posologySelected!.patientIndications,
            pharmacyIndications: this.props.posologySelected!.pharmacyIndications,
            pin: this.props.otherDetails?.pin,
            isVisado: this.props.otherDetails?.isVisado,
            visadoStart: this.props.otherDetails?.visadoStart,
            visadoEnd: this.props.otherDetails?.visadoEnd,
            visadoInput: otherDetailsVisadoInput,
            packageId: this.props.otherProductSelected?.packageId,
            idAccesoryEffect: this.props.otherProductSelected?.codeCodigoNacional,
            nameAccesoryEffect: this.props.otherProductSelected?.nameProducto,
            typePosologiaEspecial: this.props.posologySelected!.typePosologiaEspecial,
            idUnidad: this.props.posologySelected!.idUnidad
        }).then(response => {
            this.props.onClose(response);
        });
    }

    private onClose = () => {
        this.setState({alertsDismiss: true}, () => {
            this.props.onClose();
        });
    }

    private onCloseProduct = () => {
        this.setState({productFieldVisible: false});
    }

    private onClickSelectMedicineProduct = (change?: boolean) => {
        if(Number(this.props.typePrescription) === EnumPrescriptionType.MEDICAMENTO ||
            Number(this.props.typePrescription) === EnumPrescriptionType.PRODUCTO_SANITARIO_CATALOGADO ||
            Number(this.props.typePrescription) === EnumPrescriptionType.NUTRICION_CATALOGADA ||
            Number(this.props.typePrescription) === EnumPrescriptionType.VACUNA_CATALOGADA ||
            Number(this.props.typePrescription) === EnumPrescriptionType.FORMULA_MAGISTRAL_CATALOGADA) {
                this.props.onClickSelectMedicine(change);
        } else {
            this.setState({productFieldVisible: true});
        }
    }

    private saveSpecification = (form: FormInstance) => {
        form.validateFields().then(values => {
            this.setState({productFieldVisible: false}, () => {
                this.props.saveSpecification(values.productSpecification)
            });
        });
    }
}

export default withTranslation('medicineConfig')(withRouter(PrescriptionConfigContainer));