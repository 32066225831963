import React, { useContext, useState } from 'react';

import { IState as IStateContainer } from './PosologyConfigContainer';
import { Col, DatePicker, Divider, Form, FormInstance, Input, Modal, notification, Radio, Row, Select } from 'antd';
import { useTranslation } from 'react-i18next';
import Medicine from '../entities/medicine';
import EnumPrescriptionFrequency from '../../../enum/EnumPrescriptionFrequency';
import { Option } from 'rc-select';
import TextArea from 'antd/lib/input/TextArea';
import Posology from '../entities/posology';
import moment from 'moment';
import Dcpf from '../entities/dcpf';
import PrescriptionAlertsPanelContainer from '../../alerts/PrescriptionAlertsPanelContainer';
import PrestadorConfigContext from '../../../context/PrestadorConfigContext';
import MedicineInfoPopOver from './MedicineInfoPopOver';

const PosologyConfig = (props: {
    visible?: boolean;
    medicine?: Medicine;
    otherProduct?: any;
    idPatient?: number;
    codeDiagnostico?: string;
    idPrescriptionGroup?: number;
    dcpf?: Dcpf;
    posologySelected?: Posology;
    productSpecification?: string;
    alertsRefreshFlag?: number;
    alertsDismiss?: boolean;
    alertsSave?: boolean;
    noAutoPackageDuration?: boolean;
    alertsRefresh: () => void;
    calculatePackaging: (form: FormInstance, packageModification: boolean) => void;
    onCancel: () => void;
    savePosology: (form: FormInstance) => void;
    getEditingPrescription: (form?: FormInstance) => any;
    setMedicine: (medicine: any) => Medicine;
    onChangeTypePosology: (type: any) => void;
} & IStateContainer) => {

    const { t } = useTranslation(['medicineConfig']);
    
    const [form] = Form.useForm();

    const [packageMod, setPackageMod] = useState(false);

    const[callAlerts,setCallAlerts] = useState(false);

    const [editingPrescription,setEditingPrescription] = useState(props.idPatient!=null ? props.getEditingPrescription(): undefined);

    const restartPackaging = () => {
        setPackageMod(false);
        props.calculatePackaging(form, false);
    }
    
    const onChangeNumber = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const { value } = e.target;
        if (isNaN(Number(value))) {
            const units = form.getFieldValue(field).slice(0, -1);
            form.setFieldsValue({...form.getFieldsValue(), [field]: units});
        }

        if (field === 'packageDuration') {
            setPackageMod(true);
        }
    }

    const validatePositiveNumber = (value: string) => {
        //if (value != '' && (!Number.isInteger(value) || (Number.isInteger(value) && Number(value)==0))) {
        if (value != '' && Number(value)<=0) {

            notification['error']({ 
                message: t('valorPositivoError')
            });

            form.setFieldsValue({
                nPackages: '',
                packageDuration: ''
            }); 

            setPackageMod(false);            
            return false;
        }

        return true;
    }

    const cleanInput = (e: React.ChangeEvent<HTMLInputElement>) => {
        const fname = e.target.id;
        form.setFieldsValue({
           [fname]: '',
           nPackages: '',
           packageDuration: ''
        });  
        
        setPackageMod(false);
    }    

    const recalculate = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        const values = form.getFieldsValue();
        if (Number(values.dosage) && Number(values.duration) && Number(values.frequencyType) && Number(values.frequency) && Number(values.packageDuration)) {        
            onChangeNumber(e, field);
            props.calculatePackaging(form, true);
        } 
    }
    const onBlurDuration = (packageModification: boolean) => {
        const values = form.getFieldsValue();
        if (props.productSpecification || (props.dcpf?.valueUnidadContenido==null && props.medicine?.valueUnitContent==null && props.otherProduct?.valueUnidadContenido==null)) {
            if (Number(values.packageDuration) && Number(values.duration) ) {
                props.calculatePackaging(form, true);
            }
        } else {
            if (Number(values.duration) && Number(values.dosage) && Number(values.frequencyType) && Number(values.frequency)) {
                props.calculatePackaging(form, packageModification);
            }
        }
    }
    const validateDuration = (e: React.ChangeEvent<HTMLInputElement>, field: string) => {
        onChangeNumber(e, field);
        const values = form.getFieldsValue();

        if (Number(values.duration) > 365) {
            notification['error']({ 
                message: t('durationNormalError')
            });
        }
    }

    const preventDecimal = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.charCode < 48 || e.charCode > 57) {
            e.preventDefault();
            e.stopPropagation();
        }
    }

    const getSufijoDosis = () => {

        if (props.unidades && props.unidades.length!=1  && (!props.posologySelected || props.posologySelected?.idUnidad)) {
            return undefined;
        } else if (props.productSpecification || props.otherProduct) {
            return props.otherProduct?.descUnidadContenido;
        } else if (props.dcpf) {
            return props.dcpf.valueUnidadContenido;
        } else {
            return props.medicine?.valueUnitContent;
        }
    }

    const getAddonAfterDosis = () => {

        if (!props.unidades || (props.posologySelected && !props.posologySelected?.idUnidad) || props.unidades?.length==1 ) {
            return undefined;
        } else {
            return (
                <Form.Item name='idUnidad' noStyle initialValue={props.posologySelected?.idUnidad || props.unidades.find(unidad => unidad.flagDefault)?.idUnidad}>
                  <Select dropdownMatchSelectWidth={false} defaultValue={EnumPrescriptionFrequency.DAYS} 
                    onChange={() => preapareCallAlerts()} onBlur={() => {maybeCallAlerts(); onBlurDuration(false);}}>
                    {props.unidades.map(unidad => <Option value={unidad.idUnidad}>{unidad.nameUnidad}</Option>)}
                </Select>
                </Form.Item>
            );
        }
    }

    const frecuencySelector = (
        <Form.Item name='frequencyType' noStyle initialValue={props.posologySelected?.frequencyType ? props.posologySelected?.frequencyType : EnumPrescriptionFrequency.HOURS}>
        <Select
            onChange={() => onBlurDuration(false)}
            dropdownMatchSelectWidth={false}
            dropdownStyle={{width: '120px'}}
            dropdownRender={(menu) => (
            <>
                {menu}
                <Divider style={{ margin: '8px 0' }} />
                <div style={{ padding: '0px 12px 8px 12px' }}>
                    <span style={{marginBottom:'8px'}}>{t("tipoDePosologia")}</span>
                    <Form.Item name="typePosologiaEspecial" noStyle initialValue={props.posologySelected?.typePosologiaEspecial ?  props.posologySelected.typePosologiaEspecial : 0}>
                        <Radio.Group
                        onChange={(e) => {
                            form.setFieldsValue({ typePosologiaEspecial: e.target.value });
                        }}
                        >
                            <Radio value={0}>{t('fijo')}</Radio>
                            <Radio value={1}>{t('siDolor')}</Radio>
                            <Radio value={2}>{t('siFiebre')}</Radio>
                        </Radio.Group>
                    </Form.Item>
                </div>
            </>
            )}
        >
            <Option value={EnumPrescriptionFrequency.HOURS}>
            {t('prescriptionFrequencyType:' + EnumPrescriptionFrequency.HOURS)}
            </Option>
            <Option value={EnumPrescriptionFrequency.DAYS}>
            {t('prescriptionFrequencyType:' + EnumPrescriptionFrequency.DAYS)}
            </Option>
            <Option value={EnumPrescriptionFrequency.WEEKS}>
            {t('prescriptionFrequencyType:' + EnumPrescriptionFrequency.WEEKS)}
            </Option>
        </Select>
        </Form.Item>
    );

    const ctxValue = useContext(PrestadorConfigContext);
    const vidalEnabled = ctxValue.data.vidalEnabled;

    const preapareCallAlerts = () => {
        setCallAlerts(true);
        setEditingPrescription(props.getEditingPrescription(form));
    }

    const maybeCallAlerts = () => {
        if (callAlerts) {
            props.alertsRefresh();
            setCallAlerts(false);
        }
    }

    return (
        <Modal title={t('posologyeModalTitle')}
            style={{ top: 20 }}
            visible={props.visible}
            onCancel={() => props.onCancel()}
            bodyStyle={{paddingTop:'0.5rem', paddingBottom: '0.5rem'}}
            width={700}
            maskClosable={false}
            destroyOnClose
            okText={t('buttons:save')} cancelText={t('buttons:cancel')}
            onOk={() => {props.savePosology(form); return true}} >

            <div className='medication-card__container'>
                {props.productSpecification ?
                    <div style={{fontSize: '1rem', fontWeight: 'bold'}}>{props.productSpecification}</div>
                :
                props.dcpf ?
                <div className='medication-card-info'>
                    <div className='medication-info'>
                        <div className='medication-name'>
                            {props.dcpf?.nameDcpf}
                        </div>
                        <div className='medication-cn'>{t('codeDcpf') + ' ' + props.dcpf?.codeDcpf}</div>
                    </div>
                </div>
                :
                props.medicine ? 
                <MedicineInfoPopOver
                    visible={true}
                    setMedicine={props.setMedicine}
                    medicine={props.medicine}
                />
                :
                props.otherProduct ?
                    <div style={{fontSize: '1rem', fontWeight: 'bold'}}>{props.otherProduct.nameProducto}</div>
                    : <></>
                }
                {props.idPatient && !props.productSpecification && !props.otherProduct && vidalEnabled && <div className={"alertsContainer posology"}>
                    <PrescriptionAlertsPanelContainer
                         visible={true}
                         idPatient={props.idPatient}
                         idPrescriptionGroup={props.idPrescriptionGroup}
                         codeDiagnostico={props.codeDiagnostico}
                         calledFrom={"posology"}
                         editingPrescription={editingPrescription}
                         refresh={props.alertsRefreshFlag}
                         dismiss={props.alertsDismiss}
                         save={props.alertsSave}
                         level={3}
                /></div>}
            </div>
            <Form layout='vertical' form={form} size='large' style={{paddingTop: '1rem'}}>
                <Row gutter={8}>
                    {props.idPatient && <Col span={12}>
                        <Form.Item name='dateStart' label={t('dateStartLabel')} rules={[{ required: true }]} initialValue={props.posologySelected?.dateStart?moment(props.posologySelected?.dateStart):moment(new Date().getTime())}>
                            <DatePicker format='L' style={{width:'100%'}} onChange={props.idPatient ? () =>{preapareCallAlerts();}: () => {}} onBlur={() => {maybeCallAlerts();}}/>
                        </Form.Item>
                    </Col>}
                    <Col span={12}>
                        <Form.Item name='duration' label={t('durationLabel')} className='input-right-align' rules={[{ required: true }]} initialValue={props.posologySelected?.duration}>
                            <Input type="number" onKeyPress={e => preventDecimal(e)} maxLength={256} suffix={t('durationSuffix')} onChange={props.idPatient ? (e) => {validatePositiveNumber(e.target.value)?validateDuration(e, 'duration'):cleanInput(e);preapareCallAlerts();}:
                        (e) => {validatePositiveNumber(e.target.value)?validateDuration(e, 'duration'):cleanInput(e);}} onBlur={() => {onBlurDuration(false);maybeCallAlerts();}} />
                        </Form.Item>
                    </Col>
                </Row>
                {(!props.dcpf?.valueUnidadContenido && !props.medicine?.valueUnitContent && !props.otherProduct?.valueUnidadContenido) && <Row gutter={8}>
                    <Radio.Group className='radio-group-posology' onChange={(type) => {props.onChangeTypePosology(type);}} value={props.typePosology}>
                        <Radio value={1}>{t('elegirNEnvases')}</Radio>
                        <Radio value={2}>{t('especificarPauta')}</Radio>
                    </Radio.Group>
                </Row>}
                {(props.dcpf?.valueUnidadContenido || props.medicine?.valueUnitContent || props.otherProduct?.valueUnidadContenido || props.typePosology==2) && <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item name='dosage' label={t('dosageLabel')} className='input-right-align' rules={[{ required: true }]} initialValue={props.posologySelected?.dosage}>
                            <Input type="number"  min="0" maxLength={256} suffix={getSufijoDosis()} addonAfter={getAddonAfterDosis()} className={props.unidades ? 'ant-input-with-addon-after' : undefined}
                            onChange={props.idPatient ? (e) => {validatePositiveNumber(e.target.value)?onChangeNumber(e, 'dosage'):cleanInput(e);preapareCallAlerts();}:
                            (e) => {validatePositiveNumber(e.target.value)?onChangeNumber(e, 'dosage'):cleanInput(e)}}  onBlur={() => {onBlurDuration(false);maybeCallAlerts();}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item name='frequency' label={t('intervalLabel')} className='input-right-align' rules={[{ required: true }]} initialValue={props.posologySelected?.frequency}>
                            <Input type="number"  min="0" onKeyPress={e => preventDecimal(e)} className='ant-input-with-addon-after' addonAfter={frecuencySelector} 
                            onChange={props.idPatient ? (e) => {validatePositiveNumber(e.target.value)?onChangeNumber(e, 'frequency'):cleanInput(e);preapareCallAlerts();}:
                            (e) => {validatePositiveNumber(e.target.value)?onChangeNumber(e, 'dosage'):cleanInput(e)}}  onBlur={() => {onBlurDuration(false);maybeCallAlerts();}} />
                        </Form.Item>
                    </Col>
                </Row>}
                
                {<div className={(props.dcpf?.valueUnidadContenido || props.medicine?.valueUnitContent || props.otherProduct?.valueUnidadContenido) && 'medication-packages__container'}>
                    <Row gutter={8}>
                        {(props.dcpf?.valueUnidadContenido || props.medicine?.valueUnitContent || props.otherProduct?.valueUnidadContenido || props.typePosology==2) && <Col span={12}>
                            <Form.Item name='packageDuration' label={t('packageDurationLabel')} className='input-right-align' rules={[{ required: true }]} shouldUpdate initialValue={props.posologySelected?.packageDuration}>
                                <Input type="number" min="0" maxLength={256} suffix={t('durationSuffix')} onChange={(e) => {validatePositiveNumber(e.target.value)?recalculate(e, 'packageDuration'):cleanInput(e)}}/>
                            </Form.Item>
                        </Col>}
                        <Col span={12}>
                            <Form.Item name='nPackages' label={t('packagingLabel')} className='input-right-align' rules={[{ required: true }]} shouldUpdate initialValue={props.posologySelected?.nPackages}>
                                <Input maxLength={256} suffix={t('packagingSuffix')} disabled={(props.dcpf?.valueUnidadContenido || props.medicine?.valueUnitContent || props.otherProduct?.valueUnidadContenido || props.typePosology==2) ? true : false} />
                            </Form.Item>
                        </Col>
                    </Row>
                    {(props.dcpf?.valueUnidadContenido || props.medicine?.valueUnitContent || props.otherProduct?.valueUnidadContenido)  &&
                        <>
                        {packageMod && !props.noAutoPackageDuration && <div className='medication-packages-button' onClick={restartPackaging}>{t('packagingModifiedButton')}</div>}
                        {!props.productSpecification && <div className='medication-packages-info'>{t(props.unidades && props.unidades.find(unidad => unidad.idUnidad == (form.getFieldValue("idUnidad")==null ? props.unidades?.find(unidad => unidad.flagDefault)?.idUnidad : form.getFieldValue("idUnidad")))?.valueRatio==null ? 'noAutoCalculoPackageMessage':'packagingMessage')}</div>}
                        </>
                    }
                    
                </div>}
                <Row gutter={8}>
                    <Col span={12}>
                        <Form.Item label={t('patientIndicationsLabel')} name='patientIndications' rules={[{ required: false}]} initialValue={props.posologySelected?.patientIndications}>
                            <TextArea  maxLength={4096} rows={2} style={{resize: 'none'}} onFocus={e => { e.currentTarget.rows=5; e.currentTarget.classList.add('floating')}} onBlur={e => { e.currentTarget.rows=2; e.currentTarget.classList.remove('floating')}}/>
                        </Form.Item>
                    </Col>
                    <Col span={12}>
                        <Form.Item label={t('pharmacyIndicationsLabel')} name='pharmacyIndications' initialValue={props.posologySelected?.pharmacyIndications}>
                            <TextArea maxLength={4096} rows={2} style={{resize: 'none'}} onFocus={e => { e.currentTarget.rows=5; e.currentTarget.classList.add('floating')}} onBlur={e => { e.currentTarget.rows=2; e.currentTarget.classList.remove('floating')}}/>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Modal>
    )
}
export default PosologyConfig;